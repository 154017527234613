import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { TranscriptType } from '../enum/model/TranscriptTypeEnum';
import { TranscriptModel } from '@app/type/model/TranscriptModel';


export const DefaultTranscript : TranscriptModel = {

    ...DefaultCoreModel,

    transcriptType : TranscriptType.Generic,
    modelType      : ModelType.Transcript,

    chatText : '',
} as const;

export const MakeTranscript = (
    overrides : Partial<TranscriptModel>,
) : TranscriptModel => ({
    ...DefaultTranscript,
    ...overrides,
    modelType : ModelType.Transcript,
});
