import { CustomPageProps } from '@app/type/framework/template/CustomPageProps';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouteHandler } from '@app/ui/template/RouteHandler';
import { ClerkUtility } from '@app/client/ClerkUtility';
import { Notifications } from '@mantine/notifications';
import { AppProvider } from '@app/client/AppProvider';
import { useReportWebVitals } from 'next/web-vitals';
import { AppScript } from './AppContent/AppScript';
import { MantineProvider } from '@mantine/core';
import { AppTheme } from '@app/client/AppTheme';
import { ClerkProvider } from '@clerk/nextjs';
import '@mantine/code-highlight/styles.css';
import '@mantine/notifications/styles.css';
import 'react-quill/dist/quill.snow.css';
import '@mantine/spotlight/styles.css';
import { trpc } from '@app/utils/trpc';
import '@mantine/tiptap/styles.css';
import '@mantine/dates/styles.css';
import { RFC } from '@app/ui/type';
import '@mantine/core/styles.css';
import { Env } from '@app/Env';


const queryClient = new QueryClient()

const NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY = Env.getValue<string>('NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY');
const NEXT_PUBLIC_ENV_CLERK_SIGN_IN_URL = Env.getValue<string>('NEXT_PUBLIC_ENV_CLERK_SIGN_IN_URL');

const App : RFC<CustomPageProps> = ({
    Component, pageProps,
}) => {

    useReportWebVitals(metric => {
        console.log(`Web Vitals - ${metric}`, {
            metric,
        })
    })

    return (
        <AppProvider>
            <QueryClientProvider client={queryClient}>
                <AppScript />
                <ClerkProvider
                    publishableKey={NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY}
                    signInUrl={NEXT_PUBLIC_ENV_CLERK_SIGN_IN_URL}
                    {...pageProps}>
                    <ClerkUtility>
                        <MantineProvider theme={AppTheme} >
                            <Notifications />

                            {/* <DialogAdmin /> */}
                            {/* <DialogDelete /> */}
                            <RouteHandler />
                            <Component {...pageProps} />
                        </MantineProvider>
                    </ClerkUtility>
                </ClerkProvider>
            </QueryClientProvider>
        </AppProvider>
    );
}


// eslint-disable-next-line import/no-default-export
export default trpc.withTRPC(App);
