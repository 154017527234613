import PathwaySaga from '@app/ui/model/Pathway/saga';
import { SingleServeSaga } from './SingleServeSaga';
import { forkWhileAll } from '../SagaHelper';
import { PusherSaga } from './PusherSaga';
import { AdminSaga } from './AdminSaga';
import { AssetSaga } from './AssetSaga';
import { AppSaga } from './AppSaga';
import { type Saga } from '@app/type/framework/core/CoreSaga';


function* saga() : Saga {

    yield* forkWhileAll([
        // DeepGramSaga.sagaMicrophoneState,
        PusherSaga.sagaInitalizePusher,
        ...SingleServeSaga,
        ...PathwaySaga,
        ...AdminSaga,
        ...AssetSaga,
        ...AppSaga,
    ]);
}

// eslint-disable-next-line import/no-default-export
export default saga;
