/* eslint-disable max-len */

import { getRelatedAssetDocumentList, selectModel, selectState } from './common';
import { RelationshipModel } from '@app/type/model/RelationshipModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DocumentModel } from '@app/type/model/DocumentModel';
import { AssetModel } from '@app/type/model/AssetModel';
import { createSelector } from '@reduxjs/toolkit';


export const selectReferenceList    = createSelector(selectState, _selectState => _selectState.referenceList);

export const selectLookupRelationship = createSelector(selectModel, _selectModel => _selectModel[ModelType.Relationship]);
export const selectLookupTranscript   = createSelector(selectModel, _selectModel => _selectModel[ModelType.Transcript  ]);
export const selectLookupCacheNode    = createSelector(selectModel, _selectModel => _selectModel[ModelType.CacheNode   ]);
export const selectLookupObjective    = createSelector(selectModel, _selectModel => _selectModel[ModelType.Objective   ]);
export const selectLookupMilestone    = createSelector(selectModel, _selectModel => _selectModel[ModelType.Milestone   ]);
export const selectLookupActivity     = createSelector(selectModel, _selectModel => _selectModel[ModelType.Activity    ]);
export const selectLookupLocation     = createSelector(selectModel, _selectModel => _selectModel[ModelType.Location    ]);
export const selectLookupAnalysis     = createSelector(selectModel, _selectModel => _selectModel[ModelType.Analysis    ]);
export const selectLookupEventLog     = createSelector(selectModel, _selectModel => _selectModel[ModelType.EventLog    ]);
export const selectLookupAccount      = createSelector(selectModel, _selectModel => _selectModel[ModelType.Account     ]);
export const selectLookupPayment      = createSelector(selectModel, _selectModel => _selectModel[ModelType.Payment     ]);
export const selectLookupInvoice      = createSelector(selectModel, _selectModel => _selectModel[ModelType.Invoice     ]);
export const selectLookupContact      = createSelector(selectModel, _selectModel => _selectModel[ModelType.Contact     ]);
export const selectLookupMessage      = createSelector(selectModel, _selectModel => _selectModel[ModelType.Message     ]);
export const selectLookupGeneric      = createSelector(selectModel, _selectModel => _selectModel[ModelType.Generic     ]);
export const selectLookupPathway      = createSelector(selectModel, _selectModel => _selectModel[ModelType.Pathway     ]);
export const selectLookupContent      = createSelector(selectModel, _selectModel => _selectModel[ModelType.Content     ]);
export const selectLookupReport       = createSelector(selectModel, _selectModel => _selectModel[ModelType.Report      ]);
export const selectLookupEvent        = createSelector(selectModel, _selectModel => _selectModel[ModelType.Event       ]);
export const selectLookupChat         = createSelector(selectModel, _selectModel => _selectModel[ModelType.Chat        ]);
export const selectLookupUser         = createSelector(selectModel, _selectModel => _selectModel[ModelType.User        ]);
export const selectLookupTask         = createSelector(selectModel, _selectModel => _selectModel[ModelType.Task        ]);
export const selectLookupJob          = createSelector(selectModel, _selectModel => _selectModel[ModelType.Job         ]);


export const selectRelationshipList = createSelector(selectLookupRelationship, _selectLookupRelationship => Object.values(_selectLookupRelationship));

export const selectLookupAsset = createSelector(
    selectModel, selectRelationshipList, (
        _selectModel, _selectRelationshipList,
    ) => {
        const assetList = Object.values(_selectModel[ModelType.Asset]);

        if(assetList.length === 0) return {};

        const userAssetList = assetList.reduce((userAssetList, asset) => {
            const assetHashList =
                _selectRelationshipList.filter(relationship =>
                    relationship.sourceModelType  === ModelType.Asset
                    && relationship.sourceModelId === asset.id,
                ).map(relationship => relationship.sourceModelId) as unknown as string[];

            return userAssetList.concat(
                assetHashList.map(assetHash =>
                    _selectModel[ModelType.Asset][assetHash]),
            );
        }, [] as AssetModel[],
        );

        const lookupAsset : Record<string, AssetModel> = {};

        userAssetList.forEach(asset => {
            lookupAsset[asset.hash as string] = asset;
        });

        return lookupAsset;
    },
);


// turn the selectLookupRelationship into a lookup object
// where the keys are the relationshipModel destModelId and
// the value is a list of relationshipModels that have that destModelId
export const selectLookupRelationshipList = createSelector(
    selectLookupRelationship,
    _selectLookupRelationship => {

        const lookupModelRelationshipList : Record<string, RelationshipModel[]> = {};

        Object.values(_selectLookupRelationship).forEach(relationship => {

            if(relationship.sourceModelHash) {
                if(!lookupModelRelationshipList[relationship.sourceModelHash])
                    lookupModelRelationshipList[relationship.sourceModelHash] = [];

                lookupModelRelationshipList[relationship.sourceModelHash].push(relationship);
            }

            if(relationship.destModelHash) {
                if(!lookupModelRelationshipList[relationship.destModelHash])
                    lookupModelRelationshipList[relationship.destModelHash] = [];

                lookupModelRelationshipList[relationship.destModelHash].push(relationship);
            }
        });

        return lookupModelRelationshipList;
    },
);

export const selectLookupDocument = createSelector(
    selectModel, _selectModel => {
        return _selectModel[ModelType.Document];
    },
);


// allow for lookups like documentListLookup[anyModel.hash] which returns a list of DocumentModel s
export const selectLookupAssetDocumentList = createSelector(
    selectLookupRelationshipList,
    selectLookupDocument,
    (_selectLookupRelationshipList, _selectLookupDocument) => {

        const lookupAssetDocumentList : Record<string, DocumentModel[]> = {};

        Object.keys(_selectLookupRelationshipList).forEach(modelHash => {

            const relationshipList = _selectLookupRelationshipList[modelHash];

            const assetDocumentList = getRelatedAssetDocumentList(
                modelHash,
                relationshipList,
                _selectLookupDocument);

            lookupAssetDocumentList[modelHash] = assetDocumentList;
        });

        return lookupAssetDocumentList;
    },
);

export const selectLookupModelChatList = createSelector(
    selectLookupRelationshipList,
    selectLookupChat,
    (_selectLookupRelationshipList, _selectLookupChat) => {

        const lookupModelChatList : Record<string, RelationshipModel[]> = {};

        Object.keys(_selectLookupRelationshipList).forEach(modelHash => {

            const relationshipList = _selectLookupRelationshipList[modelHash];

            const chatList = relationshipList.filter(relationship =>
                relationship.destModelType === ModelType.Chat);

            lookupModelChatList[modelHash] = chatList;
        });

        return lookupModelChatList;
    },
);

export const selectTranscriptList   = createSelector(selectLookupTranscript,   _selectLookupTranscript   => Object.values(_selectLookupTranscript  ));
export const selectCacheNodeList    = createSelector(selectLookupCacheNode,    _selectLookupCacheNode    => Object.values(_selectLookupCacheNode   ))
export const selectObjectiveList    = createSelector(selectLookupObjective,    _selectLookupObjective    => Object.values(_selectLookupObjective   ));
export const selectMilestoneList    = createSelector(selectLookupMilestone,    _selectLookupMilestone    => Object.values(_selectLookupMilestone   ));
export const selectDocumentList     = createSelector(selectLookupDocument,     _selectLookupDocument     => Object.values(_selectLookupDocument    ));
export const selectActivityList     = createSelector(selectLookupActivity,     _selectLookupActivity     => Object.values(_selectLookupActivity    ));
export const selectLocationList     = createSelector(selectLookupLocation,     _selectLookupLocation     => Object.values(_selectLookupLocation    ));
export const selectEventLogList     = createSelector(selectLookupEventLog,     _selectLookupEventLog     => Object.values(_selectLookupEventLog    ));
export const selectAnalysisList     = createSelector(selectLookupAnalysis,     _selectLookupAnalysis     => Object.values(_selectLookupAnalysis    ));
export const selectPathwayList      = createSelector(selectLookupPathway,      _selectLookupPathway      => Object.values(_selectLookupPathway     ));
export const selectAccountList      = createSelector(selectLookupAccount,      _selectLookupAccount      => Object.values(_selectLookupAccount     ));
export const selectPaymentList      = createSelector(selectLookupPayment,      _selectLookupPayment      => Object.values(_selectLookupPayment     ));
export const selectInvoiceList      = createSelector(selectLookupInvoice,      _selectLookupInvoice      => Object.values(_selectLookupInvoice     ));
export const selectContactList      = createSelector(selectLookupContact,      _selectLookupContact      => Object.values(_selectLookupContact     ));
export const selectMessageList      = createSelector(selectLookupMessage,      _selectLookupMessage      => Object.values(_selectLookupMessage     ));
export const selectGenericList      = createSelector(selectLookupGeneric,      _selectLookupGeneric      => Object.values(_selectLookupGeneric     ));
export const selectReportList       = createSelector(selectLookupReport,       _selectLookupReport       => Object.values(_selectLookupReport      ));
export const selectAssetList        = createSelector(selectLookupAsset,        _selectLookupAsset        => Object.values(_selectLookupAsset       ));
export const selectEventList        = createSelector(selectLookupEvent,        _selectLookupEvent        => Object.values(_selectLookupEvent       ));
export const selectUserList         = createSelector(selectLookupUser,         _selectLookupUser         => Object.values(_selectLookupUser        ));
export const selectTaskList         = createSelector(selectLookupTask,         _selectLookupTask         => Object.values(_selectLookupTask        ));
export const selectJobList          = createSelector(selectLookupJob,          _selectLookupJob          => Object.values(_selectLookupJob         ));


/* eslint-enable max-len */
