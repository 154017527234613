import { selectAuthStatus, selectIsDebugModalOpen, selectPopup } from './selector';
import { AppContextModel } from '@app/type/framework/core/AppContextModel';
import { NetworkStatus } from '@app/type/framework/core/NetworkStatus';
import { ColorTheme } from '@app/constant/framework/ColorTheme';
import { ScreenSize } from '@app/constant/framework/ScreenSize';
import { useDispatch, useSelector } from '@app/client/hook';
import React, { PropsWithChildren, useMemo } from 'react';
import { App } from '@app/ui/AppContext';
import { RFC } from '@app/ui/type';
import {
    useColorScheme, useMediaQuery, useNetwork, useOs,
    useDocumentVisibility, useReducedMotion, useIdle,
} from '@mantine/hooks';
import { Action } from './action';


export const AppContextProvider : RFC<PropsWithChildren> = ({
    children,
}) => {

    const isDebugModalOpen = useSelector(selectIsDebugModalOpen);
    const popup = useSelector(selectPopup);

    const dispatch = useDispatch();

    const authStatus = useSelector(selectAuthStatus);

    const isReducedMotion = useReducedMotion() || false;
    const tabVisibility   = useDocumentVisibility();
    const networkStatus   = useNetwork() as NetworkStatus;
    const colorScheme     = useColorScheme();
    const isIdle          = useIdle(5000);
    const os              = useOs() || 'undetermined';

    const colorTheme =  colorScheme === 'dark'
        ? ColorTheme.Dark
        : ColorTheme.Light;

    const isPhone  = useMediaQuery('(max-width: 62em)') || false;
    const isTablet = useMediaQuery('(max-width: 75em)') || false;

    const screenSize : ScreenSize =
        isPhone
            ? ScreenSize.Phone
            : isTablet
                ? ScreenSize.Tablet
                : ScreenSize.Desktop;

    const isDesktop = screenSize === ScreenSize.Desktop;
    const isMobile  = isPhone || isTablet;

    const isAuth = authStatus?.isSignedIn === true;

    // value is cached by useMemo to avoid excessive re-renders
    const context = useMemo(() => ({
        onSelectPlan : planType => dispatch(Action.selectPlan(planType)),
        onLogout     : () => dispatch(Action.logout()),
        onHelp       : _id => {},

        isDesktop, isTablet, isMobile, isPhone,

        isAuth,

        tabVisibility,
        isDebugModalOpen,
        isReducedMotion,
        networkStatus,
        colorTheme,
        screenSize,

        isIdle,
        popup,
        os,
    } as AppContextModel), [
        dispatch, isDebugModalOpen, isMobile, isTablet, popup, colorTheme, isAuth, isDesktop,
        isPhone, isReducedMotion, networkStatus, os, screenSize,tabVisibility, isIdle,
    ]);

    return (
        <App.Provider value={context}>
            {children}
        </App.Provider>
    );
};
