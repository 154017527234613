export enum SubscriptionStatusType {
    IncompleteExpired = 'IncompleteExpired',
    Incomplete        = 'Incomplete',
    Trialing          = 'Trialing',
    Canceled          = 'Canceled',
    Generic           = 'Generic',
    PastDue           = 'PastDue',
    Unpaid            = 'Unpaid',
    Active            = 'Active',
}
