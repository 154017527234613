import { useDispatch } from '@app/client/hook';
import { useRouter } from 'next/router';

import { useEffect } from 'react';
import { RFC } from '@app/ui/type';
import { Action } from '@app/client/action';


export const RouteHandler : RFC = () : React.ReactNode | null => {

    const dispatch = useDispatch();
    const router   = useRouter();

    useEffect(() => {
        switch (router.route) {
            case '/pathways/detail':
                dispatch(Action.setAsideVisible(true));
                break;

            default:
                dispatch(Action.setAsideVisible(false));
                break;
        }
    }, [dispatch, router.asPath, router.route]);

    return null;
};
