import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { PaymentType } from '../enum/model/PaymentTypeEnum';
import { PaymentModel } from '@app/type/model/PaymentModel';


export const DefaultPayment : PaymentModel = {

    ...DefaultCoreModel,

    paymentType : PaymentType.Generic,
    modelType   : ModelType.Payment,
} as const;

export const MakePayment = (
    overrides : Partial<PaymentModel>,
) : PaymentModel => ({
    ...DefaultPayment,
    ...overrides,
    modelType : ModelType.Payment,
});
