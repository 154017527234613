import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ReviewStatusType } from '../enum/model/ReviewStatusTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { TaskStatusType } from '../enum/model/TaskStatusTypeEnum';
import { TaskType } from '../enum/model/TaskTypeEnum';
import { TaskModel } from '@app/type/model/TaskModel';


export const DefaultTask : TaskModel = {

    ...DefaultCoreModel,

    modelType : ModelType.Task,
    taskType  : TaskType.Generic,

    order : 0,

    status : TaskStatusType.Pending,

    reviewStatus : ReviewStatusType.Unverified,
} as const;


export const MakeTask = (
    overrides : Partial<TaskModel>,
) : TaskModel => ({
    ...DefaultTask,
    ...overrides,
    modelType : ModelType.Task,
});
