import { type Saga } from '@app/type/framework/core/CoreSaga';
import { call, delay, select } from 'typed-redux-saga';
import { RestApi as Api } from '@app/client/RestApi';
import { selectAuthStatus } from '../selector';
import { takePayload } from '../SagaHelper';
import { Action } from '../action';


let statusCheckCount = 0;

let semaphoreActive = false;

function* sagaDocumentIndexStatus() : Saga {

    yield delay(15000);

    statusCheckCount++;

    if(semaphoreActive) return;

    semaphoreActive = true;

    const authStatus = yield* select(selectAuthStatus);

    if(!authStatus?.isSignedIn) {
        semaphoreActive = false;

        return;
    }

    console.log(`Cron - OCR ${statusCheckCount}`);

    const _result = yield* call(Api.cron.phase1_documentOCR);

    // console.log('Cron - Analysis');

    yield* call(Api.cron.phase2_documentExtract);

    // console.log('Cron - Finished');

    semaphoreActive = false;
}

function* sagaDocumentAnalysisStatus() : Saga {

    yield* takePayload(Action.adminDocumentAnalysisStatus);

    yield* call(Api.cron.phase1_documentOCR);
}


export const AdminSaga = {
    sagaDocumentAnalysisStatus,
    sagaDocumentIndexStatus,

    *[Symbol.iterator] () {
        yield this.sagaDocumentAnalysisStatus;
        yield this.sagaDocumentIndexStatus;
    },
};
