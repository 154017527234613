import { EventLogSubType } from '@app/constant/enum/server/EventLogSubTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { EventLogType } from '@app/constant/enum/server/EventLogTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { EventLogModel } from '@app/type/model/EventLogModel';


export const DefaultEventLog : EventLogModel = {

    ...DefaultCoreModel,

    eventLogSubType : EventLogSubType.Generic,
    eventLogType    : EventLogType.Generic,

    modelType : ModelType.EventLog,
    extraData : null,
    callstack : null,
    message   : null,
} as const;

export const MakeEventLog = (
    overrides : Partial<EventLogModel>,
) : EventLogModel => ({
    ...DefaultEventLog,
    ...overrides,
    modelType : ModelType.EventLog,
});
