import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { JobType } from '../enum/model/JobTypeEnum';
import { JobModel } from '@app/type/model/JobModel';


export const DefaultJob : JobModel = {

    ...DefaultCoreModel,

    modelType : ModelType.Job,
    jobType   : JobType.Generic,

    output : '',
    input  : '',
} as const;

export const MakeJob = (
    overrides : Partial<JobModel>,
) : JobModel => ({
    ...DefaultJob,
    ...overrides,
    modelType : ModelType.Job,
});
