import { AppContextModel } from '../type/framework/core/AppContextModel';
import { ColorTheme } from '@app/constant/framework/ColorTheme';
import { ScreenSize } from '../constant/framework/ScreenSize';
import { createContext } from 'react';


export const AppContext = createContext<AppContextModel>({

    isDebugModalOpen : false,
    screenSize       : ScreenSize.Desktop,
    colorTheme       : ColorTheme.Light,

    isAuth : false,

    isDesktop : true,
    isMobile  : false,
    isTablet  : false,
    isPhone   : false,

    isReducedMotion : false,
    tabVisibility   : 'visible',
    networkStatus   : { online : true },
    isIdle          : false,
    os              : 'undetermined',

    onHelp : id => {
        console.log('AppContext::onHelp', id);
    },

    onLogout : () : void => {
        console.log('AppContext::onLogout');
    },

    onSelectPlan : id => {
        console.log('AppContext::onSelectPlan', id);
    },

    popup : {
        actionPopupSuccess : null,
        isOpen             : false,
        title              : '[no title]',
    },
});

// Shorthand
export const App = AppContext;
