import { SubscriptionStatusType } from '../enum/server/business/SubscriptionStatusType';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { PlanType } from '../enum/server/business/PlanTypeEnum';
import { UserType } from '../enum/model/UserTypeEnum';
import { UserModel } from '@app/type/model/UserModel';


export const DefaultUser : UserModel = {

    ...DefaultCoreModel,

    modelType : ModelType.User,

    stripeSubscriptionId : null,
    subscriptionStatus   : SubscriptionStatusType.Generic,
    stripeCustomerId     : null,
    clioRefreshToken     : null,
    clioAccessToken      : null,
    clioUpdatedAt        : null,
    stripePriceId        : null,
    clioTokenType        : null,
    visitorHash          : null,
    clerkUserId          : null,
    planType             : PlanType.Generic,
    userType             : UserType.Generic,
} as const;


export const MakeUser = (
    overrides : Partial<UserModel>,
) : UserModel => ({
    ...DefaultUser,
    ...overrides,
    modelType : ModelType.User,
});
