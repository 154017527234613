import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { AssetType } from '@app/constant/enum/model/AssetTypeEnum';
import { AssetModel } from '@app/type/model/AssetModel';


export const DefaultAsset : AssetModel = {

    ...DefaultCoreModel,

    assetType : AssetType.Generic,
    modelType : ModelType.Asset,
} as const;

export const MakeAsset = (
    overrides : Partial<AssetModel>,
) : AssetModel => ({
    ...DefaultAsset,
    ...overrides,
    modelType : ModelType.Asset,
});
