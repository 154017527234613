import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { ReportType } from '../enum/model/ReportTypeEnum';
import { ReportModel } from '@app/type/model/ReportModel';


export const DefaultReport : ReportModel = {

    ...DefaultCoreModel,

    reportType : ReportType.Generic,
    modelType  : ModelType.Report,
} as const;

export const MakeReport = (
    overrides : Partial<ReportModel>,
) : ReportModel => ({
    ...DefaultReport,
    ...overrides,
    modelType : ModelType.Report,
});
