import { refreshPathwayFullDetailAction } from '@app/ui/page/detail/PagePathwayEnrolled/action';
import { selectPathwayDetailChat } from '@app/client/selector/PathwaySelector';
import { DefaultObjective } from '@app/constant/default/DefaultObjective';
import { DefaultChat } from '@app/constant/default/DefaultChat';
import { Saga } from '@app/type/framework/core/CoreSaga';
import { call, select, take } from 'typed-redux-saga';
import { takePayload } from '@app/client/SagaHelper';
import { RestApi as Api } from '@app/client/RestApi';
import { generatePatentAction } from './action';
import { sagaDispatch } from '@app/client';
import Router from 'next/router';
import { Action } from '@app/client/action';

// Clicking on the chat in the chat list. Fetch the chat
function* sagaSelectPathwayDetailChat() : Saga {

    const selectedChatHash = yield* takePayload(Action.selectPathwayDetailChat);

    if (!selectedChatHash) return;

    const { model : _chat, modelStash } = yield* call(Api.chat.get, selectedChatHash);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));
}

// Run analysis on the current state if the ChatModel
function* sagaChatAnalysis() : Saga {

    yield* takePayload(Action.chatAnalysis);

    // const chat = yield* select(Selector.pathwayDetailChat);

    const chat = DefaultChat;

    if (chat === null) {
        console.log('No chat selected');

        return
    }


    const { modelStash } = yield* call(Api.chat.analysis, chat);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));
}

// Click the "Create New Chat" button
function* sagaCreatePathwayChat() : Saga {

    const pendingChat = yield* takePayload(Action.createPathwayChat);

    const { modelStash } = yield* call(Api.chat.create, pendingChat);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));
}

function* sagaRefreshPathwayFullDetail() : Saga {

    const pathwayHash = yield* takePayload(refreshPathwayFullDetailAction);

    const { modelStash } = yield* call(Api.pathway.get, pathwayHash);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));
}

// append an objective to the current Chat and refresh
// any updated models in the stash
function* sagaAddObjectiveToChat() : Saga {

    yield* takePayload(Action.addObjectiveToChat);

    // is this used?
    // todo fix this as pathway refactor
    const pendingObjective = DefaultObjective;
    const parentChat       = yield* select(selectPathwayDetailChat);

    if (!parentChat) {
        console.log('No parent chat selected');

        return;
    }

    const { modelStash } = yield* call(Api.objective.create, {
        ...pendingObjective,
        parentChatId : parentChat.id,
    });

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));
}

const isRecorderReceivedTranscript = (actionType : string) : boolean => actionType === Action.recorderReceivedTranscript.type;
const isSetRecorderState           = (actionType : string) : boolean => actionType === Action.setRecorderState.type;

function* sagaRecordChatStatus() : Saga {

    let isRecording = yield* takePayload(Action.setRecorderState);

    while (isRecording) {
        const action = yield* take([
            Action.recorderReceivedTranscript,
            Action.setRecorderState,
        ]);

        if (isRecorderReceivedTranscript(action.type))
            yield* sagaAddTranscriptToChat(action.payload);

        else if (isSetRecorderState(action.type))
            isRecording = action.payload as boolean;
    }
}

function* sagaAddTranscriptToChat(transcriptText : string) : Saga<unknown> {

    const parentChat = yield* select(selectPathwayDetailChat);

    if (!parentChat) {
        console.log('Unable to add transcript, no parent chat was found');

        return;
    }


    const { modelStash } = yield* call(Api.transcript.create, {
        parentChatId : parentChat.id,
        title        : transcriptText,
    });

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));
}

function* sagaPublishPathway() : Saga {

    const inputPathway = yield* takePayload(Action.publishPathway);

    const { model : pathway, modelStash } = yield* call(Api.pathway.publish, inputPathway);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));

    Router.push(`/patent/published/${pathway.hash}`);
}

function* sagaStartPathway() : Saga {

    const inputPathway = yield* takePayload(Action.startPathway);

    const { model : pathway, modelStash } = yield* call(Api.pathway.start, inputPathway);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));

    Router.push(`/patent/enrolled/${pathway.hash}`);
}

function* sagaGeneratePatentAction() : Saga {
    const pathwayHash = yield* takePayload(generatePatentAction);

    const { modelStash } = yield* call(Api.pathway.generatePatent, pathwayHash);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));
}


const PathwaySaga = {
    sagaRefreshPathwayFullDetail, // Refresh the full pathway when navigating to the pathway page
    sagaSelectPathwayDetailChat,  // Clicking on the chat in the chat list. Fetch the chat
    sagaAddObjectiveToChat,       // Click the 'Add Objective" button
    sagaCreatePathwayChat,        // Click the "Create New Chat" button
    sagaRecordChatStatus,
    sagaPublishPathway,
    sagaStartPathway,
    sagaChatAnalysis, // Running analysis of the current ChatModel
    sagaGeneratePatentAction,

    *[Symbol.iterator] () {
        yield this.sagaRefreshPathwayFullDetail;
        yield this.sagaSelectPathwayDetailChat;
        yield this.sagaAddObjectiveToChat;
        yield this.sagaCreatePathwayChat;
        yield this.sagaRecordChatStatus;
        yield this.sagaPublishPathway;
        yield this.sagaStartPathway;
        yield this.sagaChatAnalysis;
        yield this.sagaGeneratePatentAction;

    },
};

// eslint-disable-next-line import/no-default-export
export default PathwaySaga;
