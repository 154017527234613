import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { AccountType } from '../enum/model/AccountTypeEnum';
import { AccountModel } from '@app/type/model/AccountModel';


export const DefaultAccount : AccountModel = {

    ...DefaultCoreModel,

    accountType : AccountType.Generic,
    modelType   : ModelType.Account,
} as const;

export const MakeAccount = (
    overrides : Partial<AccountModel>,
) : AccountModel => ({
    ...DefaultAccount,
    ...overrides,
    modelType : ModelType.Account,
});
