import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ReviewStatusType } from '../enum/model/ReviewStatusTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { GenericType } from '../enum/model/GenericTypeEnum';
import { GenericModel } from '@app/type/model/GenericModel';


export const DefaultGeneric : GenericModel = {

    reviewStatus : ReviewStatusType.Unverified,

    ...DefaultCoreModel,

    genericType : GenericType.Generic,
    modelType   : ModelType.Generic,
} as const;


export const MakeGeneric = (
    overrides : Partial<GenericModel>,
) : GenericModel => ({
    ...DefaultGeneric,
    ...overrides,
    modelType : ModelType.Generic,
});
