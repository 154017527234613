import { MilestoneStatusType } from '@app/constant/enum/model/MilestoneStatusTypeEnum';
import { MilestoneType } from '@app/constant/enum/model/MilestoneTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { MilestoneModel } from '@app/type/model/MilestoneModel';
import { DefaultCoreModel } from './DefaultCoreModel';


export const DefaultMilestone : MilestoneModel = {

    ...DefaultCoreModel,

    milestoneType : MilestoneType.Generic,
    modelType     : ModelType.Milestone,

    createdAt : null,
    updatedAt : null,
    createdBy : null,
    title     : '',
    hash      : null,
    id        : null,
    debug     : '',

    dateCompleted : null,
    dateStarted   : null,
    clientTitle   : 'Client Milestone Title',
    dateDue       : null,
    status        : MilestoneStatusType.Pending,

    order         : 0,
    logicInit     : '',
    logicDue      : '',
    logicDone     : '',
    logicFail     : '',
    logicLate     : '',
    logicSkip     : '',
    logicApproved : '',

} as const;


export const MakeMilestone = (
    overrides : Partial<MilestoneModel>,
) : MilestoneModel => ({
    ...DefaultMilestone,
    ...overrides,
});
