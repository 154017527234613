import { AppRouter } from '@app/server/routers/_app';
import { httpBatchLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import { Env } from '@app/Env';


const getBaseUrl = () : string => {


    if (typeof window !== 'undefined')
        return '';

    return Env.getValue('NEXT_PUBLIC_BROWSER_WINDOW_LOCATION_ORIGIN');
}

export const trpc = createTRPCNext<AppRouter>({
    config({ ctx : _ctx}) {
        return {
            links : [

                httpBatchLink({

                    url : `${getBaseUrl()}/api/trpc`,

                    async headers() {
                        return {
                            // authorization: getAuthCookie(),
                        };
                    },
                }),
            ],
        };
    },
    ssr : false,
});
