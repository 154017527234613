export enum MessageType {
    Notification = 'Notification',
    Generic      = 'Generic',
    Comment      = 'Comment',
    Direct       = 'Direct',
    Social       = 'Social',
    Email        = 'Email',
    Alert        = 'Alert',
    Chat         = 'Chat',
    Post         = 'Post',
    Note         = 'Note',
}
