import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { InvoiceType } from '../enum/model/InvoiceTypeEnum';
import { InvoiceModel } from '@app/type/model/InvoiceModel';


export const DefaultInvoice : InvoiceModel = {

    ...DefaultCoreModel,

    invoiceType : InvoiceType.Generic,
    modelType   : ModelType.Invoice,
} as const;


export const MakeInvoice = (
    overrides : Partial<InvoiceModel>,
) : InvoiceModel => ({
    ...DefaultInvoice,
    ...overrides,
    modelType : ModelType.Invoice,
});
