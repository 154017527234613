import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { ChatType } from '@app/constant/enum/model/ChatTypeEnum';
import { DefaultCoreModel } from './DefaultCoreModel';
import { ChatModel } from '@app/type/model/ChatModel';


export const DefaultChat : ChatModel = {

    ...DefaultCoreModel,

    chatType  : ChatType.Generic,
    modelType : ModelType.Chat,

    relatedContentHash : null,
    analysisSummary    : '',
    groupOrder         : 0,
    groupHash          : '',
    chatOrder          : 0,
    notes              : '',
    query              : '',

    auditLog : '',

    patent_publications : [],

} as const;

export const MakeChat = (
    overrides : Partial<ChatModel>,
) : ChatModel => ({
    ...DefaultChat,
    ...overrides,
    modelType : ModelType.Chat,
});
