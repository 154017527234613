import { ReviewStatusType } from '@app/constant/enum/model/ReviewStatusTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { EventType } from '@app/constant/enum/model/EventTypeEnum';
import { EventModel } from '@app/type/model/EventModel';


export const DefaultEvent : EventModel = {

    reviewStatus : ReviewStatusType.Unverified,

    ...DefaultCoreModel,

    eventType : EventType.Generic,
    modelType : ModelType.Event,
} as const;


export const MakeEvent = (
    overrides : Partial<EventModel>,
) : EventModel => ({
    ...DefaultEvent,
    ...overrides,
    modelType : ModelType.Event,
});
