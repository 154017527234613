import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ReviewStatusType } from '../enum/model/ReviewStatusTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { LocationType } from '../enum/model/LocationTypeEnum';
import { LocationModel } from '@app/type/model/LocationModel';


export const DefaultLocation : LocationModel = {

    reviewStatus : ReviewStatusType.Unverified,

    ...DefaultCoreModel,

    locationType : LocationType.Generic,
    modelType    : ModelType.Location,
} as const;

export const MakeLocation = (
    overrides : Partial<LocationModel>,
) : LocationModel => ({
    ...DefaultLocation,
    ...overrides,
    modelType : ModelType.Location,
});
