export enum EventLogType {
    Performance = 'Performance',
    Security    = 'Security',
    Success     = 'Success',
    Generic     = 'Generic',
    Warning     = 'Warning',
    Error       = 'Error',
    Debug       = 'Debug',
    Trace       = 'Trace',
    Audit       = 'Audit',
    Info        = 'Info',
}
