import { EventChannelCoreAction } from '@app/type/framework/core/EventChannelCoreAction';
import { PusherChannel, PusherServerAction } from '@app/constant/framework/Pusher';
import { CoreAction } from '@app/type/framework/core/CoreAction';
import { type Saga } from '@app/type/framework/core/CoreSaga';
import { call, fork, put, take } from 'typed-redux-saga';
import { eventChannel } from 'redux-saga';
import Pusher from 'pusher-js';
import { Env } from '@app/Env';


const createChannelPusher = (pusher : Pusher) : EventChannelCoreAction =>
    eventChannel(dispatch => {

        pusher.subscribe(PusherChannel).bind(
            PusherServerAction,
            (action : CoreAction) => {

                return dispatch(action);
            },
        );

        return () => {};
    });

function* sagaDispatchChannelAction(channel : EventChannelCoreAction) : Saga {
    while (true) try {
        yield* put(yield* take(channel));
    } catch (exc) {
        console.error('Pusher Dispatcher Error:', exc);
    }
}

const NEXT_PUBLIC_PUSHER_CLUSTER = Env.getValue<string>('NEXT_PUBLIC_PUSHER_CLUSTER');
const NEXT_PUBLIC_PUSHER_KEY     = Env.getValue<string>('NEXT_PUBLIC_PUSHER_KEY');

function* sagaInitalizePusher() : Saga {

    let channelPusher : EventChannelCoreAction | null = null;

    const pusher = new Pusher(NEXT_PUBLIC_PUSHER_KEY, {
        cluster : NEXT_PUBLIC_PUSHER_CLUSTER,
    });

    channelPusher = yield* call(createChannelPusher, pusher);

    yield* fork(sagaDispatchChannelAction, channelPusher)
}


export const PusherSaga = {
    sagaDispatchChannelAction,
    sagaInitalizePusher,
    createChannelPusher,

    *[Symbol.iterator] () {
        yield this.sagaDispatchChannelAction;
        yield this.sagaInitalizePusher;
    },
};
