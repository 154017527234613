import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { ObjectiveType } from '../enum/model/ObjectiveTypeEnum';
import { ObjectiveModel } from '@app/type/model/ObjectiveModel';


export const DefaultObjective : ObjectiveModel = {

    ...DefaultCoreModel,

    objectiveType : ObjectiveType.Generic,
    modelType     : ModelType.Objective,

    parentChatId : null,

    ParentChat : null,
} as const;

export const MakeObjective = (
    overrides : Partial<ObjectiveModel>,
) : ObjectiveModel => ({
    ...DefaultObjective,
    ...overrides,
    modelType : ModelType.Objective,
});
