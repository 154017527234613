import { DialogModalStateType } from '@app/constant/enum/ui/DialogModalStateTypeEnum';
import { type PayloadAction as PA , createSlice, original } from '@reduxjs/toolkit';
import { TaskPositionType } from '@app/constant/enum/ui/TaskPositionTypeEnum';
import { ClerkAuthStatus } from '@app/type/framework/utility/ClerkAuthStatus';
import { DefaultTranscript } from '@app/constant/default/DefaultTranscript';
import { InitialState } from '@app/constant/framework/InitialState';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { CoreAppRoot } from '@app/type/framework/core/CoreAppRoot';
import { ProjectName } from '@app/constant/framework/ProjectName';
import { CacheNodeModel }  from '@app/type/model/CacheNodeModel';
import { MilestoneModel }  from '@app/type/model/MilestoneModel';
import { ObjectiveModel }  from '@app/type/model/ObjectiveModel';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { CoreError } from '@app/type/framework/core/CoreError';
import { ActivityModel }  from '@app/type/model/ActivityModel';
import { AnalysisModel }  from '@app/type/model/AnalysisModel';
import { EventLogModel }  from '@app/type/model/EventLogModel';
import { LocationModel }  from '@app/type/model/LocationModel';
import { AccountModel }  from '@app/type/model/AccountModel';
import { ContactModel }  from '@app/type/model/ContactModel';
import { InvoiceModel }  from '@app/type/model/InvoiceModel';
import { MessageModel }  from '@app/type/model/MessageModel';
import { PathwayModel }  from '@app/type/model/PathwayModel';
import { PaymentModel }  from '@app/type/model/PaymentModel';
import { ReportModel }  from '@app/type/model/ReportModel';
import { updateComposeModelAction } from './CommonAction';
import { ModelLookup } from '@app/type/model/ModelLookup';
import { AssetModel }  from '@app/type/model/AssetModel';
import { EventModel }  from '@app/type/model/EventModel';
import { ModelStash } from '@app/type/model/ModelStash';
import { TaskModel }  from '@app/type/model/TaskModel';
import { UserModel }  from '@app/type/model/UserModel';
import { ChatModel } from '@app/type/model/ChatModel';
import { SharedAction } from '@app/shared/SharedAction';
import { type WritableDraft } from 'immer';
import { Action } from './action';
import _ from 'lodash';


const utilMapItems = <T extends CoreModel>(
    itemList : T[],
) : {[key : string] : T} => {

    const itemMap : {[key : string] : T} = {};

    itemList.forEach((item : T) => {
        itemMap[item.hash as string] = item;
    });

    return itemMap;
};

const filterIncomingUpdateList = <T extends CoreModel>(
    list  : T[],
    stash : WritableDraft<ModelStash>,
) : T[] => {

    if (list.length === 0)
        return list;

    // iterate through the list of CoreModels and look them up
    // in the state.model[modelType] object. If they exist, then
    // compare the updatedAt property and discard it from the list
    // if it is older than then one in the stash already. If the
    // two models have the same updatedAt property, then replace
    // the existing value if the current model has a `CreatedBy` (capitalization important)
    // property that is null and replace it
    const filteredUpdateList = list.filter((incomingModel : CoreModel) => {

        const modelLookup = stash[incomingModel.modelType.toString()];

        const existingModel = modelLookup[incomingModel.hash as string];

        if (!existingModel) return true;

        if (!existingModel.updatedAt || !incomingModel.updatedAt)
            throw new Error(`Missing updatedAt property ${existingModel.hash} ${incomingModel.hash}`);

        const existingUpdatedAt = new Date(existingModel.updatedAt);
        const incomingUpdatedAt = new Date(incomingModel.updatedAt);

        if (existingUpdatedAt > incomingUpdatedAt) {
            console.log('The incoming model is older than the existing model, discarding incoming model');

            return false;
        }

        if (existingUpdatedAt < incomingUpdatedAt) {
            console.log('The incoming model is newer than the existing model, replacing existing model');

            return true;
        }

        switch (existingModel.modelType) {
            case ModelType.User: {
                const _existingUser = existingModel as UserModel;
                const _incomingUser = incomingModel as UserModel;
            } break;

            case ModelType.Chat: {
                const existingChat = existingModel as ChatModel;
                const incomingChat = incomingModel as ChatModel;

                if (existingChat.ObjectiveList !== null && incomingChat.ObjectiveList === null) {
                    console.log('Existing Chat model better than incoming, discarding incoming model');

                    return false;
                }
            } break;
        }

        return true;
    });

    return filteredUpdateList;
};

const slice = createSlice({
    initialState : InitialState,
    name         : ProjectName,

    extraReducers : builder => {

        builder.addCase(Action.pushError, (state, action : PA<CoreError>) => {
            state.errorList.push(action.payload);
        });

        builder.addCase(Action.webSocketEventMessage, (state, { payload }) => {
            console.log('Action.webSocketEventMessage', payload);
        });

        builder.addCase(Action.webSocketEventClose, (state, { payload }) => {
            console.log('Action.webSocketEventClose', payload);
        });

        builder.addCase(Action.webSocketEventOpen, (state, { payload }) => {
            console.log('Action.webSocketEventOpen', payload);
        });

        builder.addCase(Action.toggleTemplateNavbar, state => {
            state.template.isTemplateNavbarOpen = !state.template.isTemplateNavbarOpen;
        });

        builder.addCase(Action.clickNavLink, state => {
            state.template.isTemplateNavbarOpen = false;
        });

        builder.addCase(SharedAction.pusherNotification, (state, { payload : notification}) => {
            state.serverActionLog.push({
                loading   : false,
                autoClose : true,
                message   : '',
                title     : '',
                ...notification,
            });
        });

        builder.addCase(Action.openNewModelDialog, (state, { payload : modelType }) => {
            state.modelBrowsePage[modelType].dialogModalStateType = DialogModalStateType.Open;
        });

        builder.addCase(Action.closeNewModelDialog, (state, { payload : modelType }) => {
            state.modelBrowsePage[modelType].dialogModalStateType = DialogModalStateType.Closed;
        });

        builder.addCase(Action.updateDialogPosition, (state, { payload : dialogPosition }) => {
            state.dialog.dialogPosition = dialogPosition;
        });

        builder.addCase(Action.toggleDialogFullscreen, state => {
            state.dialog.isFullscreen = !state.dialog.isFullscreen;
        });

        builder.addCase(Action.paythwayBuilderMilestonClicked, (state, { payload : hash }) => {
            state.modelDetailPage.Pathway.selectedMilestoneHash = hash;
        });

        builder.addCase(Action.selectPathwayAsset, (state, { payload : assetHash }) => {
            state.modelDetailPage.Pathway.selectedAssetHash = assetHash;
        });

        builder.addCase(Action.initalizeComplete, state => {
            state.isInitalizeComplete = true;
        });

        builder.addCase(Action.updateAdmiChatPrompt, (state, { payload : chatPrompt }) => {
            state.admin.chatPrompt = chatPrompt;
        });

        builder.addCase(Action.updateAdmiChatCompletion, (state, { payload : chatCompletion }) => {
            state.admin.chatCompletion = chatCompletion;
        });

        builder.addCase(Action.confirmDeleteModel, (state, { payload : coreModel }) => {
            if(!coreModel) {
                state.confirmDelete = {
                    modelType : null,
                    modelHash : null,
                };

                return;
            }

            state.confirmDelete = {
                modelType : coreModel.modelType,
                modelHash : coreModel.hash,
            };
        });

        builder.addCase(Action.updateComposeModelDetail, (state, { payload : incomingModel }) => {
            if (!incomingModel)
                return;

            // do a deep compare to seee if the models are equal. If they are, then
            // don't update the state and log to the console debug data

            const isNullStoreValue = state.modelDetailPage[incomingModel.modelType].model === null;

            const existingStoreModel = isNullStoreValue
                ? null
                : original(state.modelDetailPage[incomingModel.modelType].model);

            console.log('Slice: ---------')
            console.log('Slice: Incoming', incomingModel);
            console.log('Slice: Existing', existingStoreModel);

            const diff = _.differenceWith([existingStoreModel], [incomingModel], _.isEqual);

            // if (state.modelDetailPage[model.modelType].model === model) {
            if (_.isEqual(existingStoreModel, incomingModel)) {
                console.log(`Slice: The model is the same, not updating the state ${incomingModel}`);


                return;
            }

            console.log('Slice: Updating stash, The model is different, diff is:', diff);

            state.modelDetailPage[incomingModel.modelType].model = incomingModel;
        });

        builder.addCase(Action.openTask, (state, { payload : taskHash }) => {
            state.task = {
                ...state.task,
                position : TaskPositionType.FullScreen,
                taskHash,
            };
        });

        builder.addCase(Action.contextMenuUpdateAsideStatus, (state, { payload : { asideStatus, model } }) => {
            state.template.asideStatus = asideStatus;

            if(model)
                state.referenceList.push(model.hash);
        });

        builder.addCase(Action.updateResearchQueryText, (state, { payload : {
            researchQueryText, researchControlId,
        } }) => {
            state.researchControlList[researchControlId] = researchQueryText;
        });


        builder.addCase(Action.selectPathwayDetailChat, (state, { payload : selectedChatHash }) => {
            console.log('Updating the pathway chat', selectedChatHash);

            state.modelDetailPage.Pathway.selectedChatHash = selectedChatHash;
        });

        builder.addCase(Action.changeNavbarPosition, (state, { payload : navbarPosition }) => {
            state.template = {
                ...state.template,
                navbarPosition,
            };
        });

        builder.addCase(Action.recorderReceivedTranscript, (state, { payload : title }) => {
            state.transcriptList.push({
                ...DefaultTranscript,
                createdAt : new Date().toISOString(),
                title,
            });
        });

        builder.addCase(Action.changeHeaderPosition, (state, { payload : headerPosition }) => {
            state.template = {
                ...state.template,
                headerPosition,
            };
        });

        builder.addCase(Action.updateWeatherAnalysis, (state, { payload : weatherAnalysis }) => {
            state.contentPage.dashboard = {
                ...state.contentPage.dashboard,
                weatherAnalysis,
            };
        });

        builder.addCase(Action.updateStashModelSync, (state, { payload : { model } }) => {
            state.model[model.modelType][model.hash as string] = model;
        });

        builder.addCase(Action.updateStashNoSync, (state, { payload : stash }) => {
            Object.keys(stash).forEach(modelType => {
                Object.values(stash[modelType as ModelLookup]).forEach(model => {
                    state.model[modelType as ModelLookup][model.hash as string] = model;
                });
            });
        });

        builder.addCase(updateComposeModelAction, (state, { payload : model }) => {
            state.modelBrowsePage[model.modelType].model = model
        });

        builder.addCase(Action.setAppLoaded, state => {
            state.isAppLoaded = true;
        });

        builder.addCase(Action.setRecorderState, (state, { payload : isRecording }) => {
            state.isRecording = isRecording;
        });

        builder.addCase(Action.selectNavbarItemId, (state, { payload : itemId }) => {
            state.template.selectedNavbarItemId = itemId;
        });

        builder.addCase(Action.submitResearchChatText, (state, { payload }) => {
            const { id } = payload;

            state.research[id] = {
                ...state.research[id],
                isLoading : true,
            };
        });

        builder.addCase(Action.composePathwayChat, (state, { payload : dialogModalStateType }) => {
            state.modelDetailPage[ModelType.Pathway].dialogModalStateType = dialogModalStateType;
        });

        builder.addCase(Action.submitResearchText, (state, { payload : _asdf }) => {
            state.modelDetailPage[ModelType.Pathway].isResearching = true;
        });

        builder.addCase(Action.researchComplete, (state, { payload : _modelType }) => {
            state.modelDetailPage[ModelType.Pathway].isResearching = false;
        });

        // builder.addCase(Action.updatePageModel, (state, { payload : model }) => {
        //     state.modelDetailPage[model.modelType]. = model;
        // });

        const storeModel = (
            state     : WritableDraft<CoreAppRoot>,
            modelType : ModelLookup,
            list      : CoreModel[] | undefined,
        ) : void => {
            if (!list)  return;

            const filteredUpdateList = filterIncomingUpdateList(list, state.model);

            if (filteredUpdateList.length !== list.length)
                console.log('See if the selective update thing worked here');

            state.model = {
                ...state.model,
                [modelType] : {
                    ...state.model[modelType] ,
                    ...utilMapItems(filteredUpdateList),
                },
            };
        };

        const removeModel = (
            state     : WritableDraft<CoreAppRoot>,
            modelType : ModelLookup,
            list      : CoreModel[],
        ) : void =>
            list.forEach(({ hash } : CoreModel) => {
                if (state.model[modelType][hash as string])
                    delete state.model[modelType][hash as string];
            });

        builder.addCase(Action.removeModelMilestone, (state, { payload: model }) => { removeModel(state, ModelType.Milestone, [model]); });
        builder.addCase(Action.removeModelCacheNode, (state, { payload: model }) => { removeModel(state, ModelType.CacheNode, [model]); });
        builder.addCase(Action.removeModelObjective, (state, { payload: model }) => { removeModel(state, ModelType.Objective, [model]); });
        builder.addCase(Action.removeModelEventLog,  (state, { payload: model }) => { removeModel(state, ModelType.EventLog,  [model]); });
        builder.addCase(Action.removeModelLocation,  (state, { payload: model }) => { removeModel(state, ModelType.Location,  [model]); });
        builder.addCase(Action.removeModelActivity,  (state, { payload: model }) => { removeModel(state, ModelType.Activity,  [model]); });
        builder.addCase(Action.removeModelAnalysis,  (state, { payload: model }) => { removeModel(state, ModelType.Analysis,  [model]); });
        builder.addCase(Action.removeModelDocument,  (state, { payload: model }) => { removeModel(state, ModelType.Document,  [model]); });
        builder.addCase(Action.removeModelPathway,   (state, { payload: model }) => { removeModel(state, ModelType.Pathway,   [model]); });
        builder.addCase(Action.removeModelContact,   (state, { payload: model }) => { removeModel(state, ModelType.Contact,   [model]); });
        builder.addCase(Action.removeModelAccount,   (state, { payload: model }) => { removeModel(state, ModelType.Account,   [model]); });
        builder.addCase(Action.removeModelInvoice,   (state, { payload: model }) => { removeModel(state, ModelType.Invoice,   [model]); });
        builder.addCase(Action.removeModelPayment,   (state, { payload: model }) => { removeModel(state, ModelType.Payment,   [model]); });
        builder.addCase(Action.removeModelMessage,   (state, { payload: model }) => { removeModel(state, ModelType.Message,   [model]); });
        builder.addCase(Action.removeModelReport,    (state, { payload: model }) => { removeModel(state, ModelType.Report,    [model]); });
        builder.addCase(Action.removeModelEvent,     (state, { payload: model }) => { removeModel(state, ModelType.Event,     [model]); });
        builder.addCase(Action.removeModelAsset,     (state, { payload: model }) => { removeModel(state, ModelType.Asset,     [model]); });
        builder.addCase(Action.removeModelChat,      (state, { payload: model }) => { removeModel(state, ModelType.Chat,      [model]); });
        builder.addCase(Action.removeModelUser,      (state, { payload: model }) => { removeModel(state, ModelType.User,      [model]); });
        builder.addCase(Action.removeModelTask,      (state, { payload: model }) => { removeModel(state, ModelType.Task,      [model]); });


        builder.addCase(Action.deleteStashModel, (state, { payload: model }) => {

            switch(model.modelType) {
                case ModelType.CacheNode: return removeModel(state, ModelType.CacheNode, [model]);
                case ModelType.Milestone: return removeModel(state, ModelType.Milestone, [model]);
                case ModelType.Objective: return removeModel(state, ModelType.Objective, [model]);
                case ModelType.EventLog : return removeModel(state, ModelType.EventLog,  [model]);
                case ModelType.Location : return removeModel(state, ModelType.Location,  [model]);
                case ModelType.Activity : return removeModel(state, ModelType.Activity,  [model]);
                case ModelType.Analysis : return removeModel(state, ModelType.Analysis,  [model]);
                case ModelType.Document : return removeModel(state, ModelType.Document,  [model]);
                case ModelType.Pathway  : return removeModel(state, ModelType.Pathway,   [model]);
                case ModelType.Contact  : return removeModel(state, ModelType.Contact,   [model]);
                case ModelType.Account  : return removeModel(state, ModelType.Account,   [model]);
                case ModelType.Invoice  : return removeModel(state, ModelType.Invoice,   [model]);
                case ModelType.Payment  : return removeModel(state, ModelType.Payment,   [model]);
                case ModelType.Message  : return removeModel(state, ModelType.Message,   [model]);
                case ModelType.Report   : return removeModel(state, ModelType.Report,    [model]);
                case ModelType.Event    : return removeModel(state, ModelType.Event,     [model]);
                case ModelType.Asset    : return removeModel(state, ModelType.Asset,     [model]);
                case ModelType.Chat     : return removeModel(state, ModelType.Chat,      [model]);
                case ModelType.User     : return removeModel(state, ModelType.User,      [model]);
                case ModelType.Task     : return removeModel(state, ModelType.Task,      [model]);
            }
        });

        /* eslint-disable max-len */

        builder.addCase(Action.storeMilestoneList, (state, { payload: list }) => { storeModel(state, ModelType.Milestone, list as MilestoneModel[]); });
        builder.addCase(Action.storeCacheNodeList, (state, { payload: list }) => { storeModel(state, ModelType.CacheNode, list as CacheNodeModel[]); });
        builder.addCase(Action.storeObjectiveList, (state, { payload: list }) => { storeModel(state, ModelType.Objective, list as ObjectiveModel[]); });
        builder.addCase(Action.storeEventLogList,  (state, { payload: list }) => { storeModel(state, ModelType.EventLog,  list as EventLogModel[] ); });
        builder.addCase(Action.storeLocationList,  (state, { payload: list }) => { storeModel(state, ModelType.Location,  list as LocationModel[] ); });
        builder.addCase(Action.storeActivityList,  (state, { payload: list }) => { storeModel(state, ModelType.Activity,  list as ActivityModel[] ); });
        builder.addCase(Action.storeAnalysisList,  (state, { payload: list }) => { storeModel(state, ModelType.Analysis,  list as AnalysisModel[] ); });
        builder.addCase(Action.storeContactList,   (state, { payload: list }) => { storeModel(state, ModelType.Contact,   list as ContactModel[]  ); });
        builder.addCase(Action.storeAccountList,   (state, { payload: list }) => { storeModel(state, ModelType.Account,   list as AccountModel[]  ); });
        builder.addCase(Action.storeInvoiceList,   (state, { payload: list }) => { storeModel(state, ModelType.Invoice,   list as InvoiceModel[]  ); });
        builder.addCase(Action.storePaymentList,   (state, { payload: list }) => { storeModel(state, ModelType.Payment,   list as PaymentModel[]  ); });
        builder.addCase(Action.storeMessageList,   (state, { payload: list }) => { storeModel(state, ModelType.Message,   list as MessageModel[]  ); });
        builder.addCase(Action.storePathwayList,   (state, { payload: list }) => { storeModel(state, ModelType.Pathway,   list as PathwayModel[]  ); });
        builder.addCase(Action.storeReportList,    (state, { payload: list }) => { storeModel(state, ModelType.Report,    list as ReportModel[]   ); });
        builder.addCase(Action.storeEventList,     (state, { payload: list }) => { storeModel(state, ModelType.Event,     list as EventModel[]    ); });
        builder.addCase(Action.storeAssetList,     (state, { payload: list }) => { storeModel(state, ModelType.Asset,     list as AssetModel[]    ); });
        builder.addCase(Action.storeChatList,      (state, { payload: list }) => { storeModel(state, ModelType.Chat,      list as ChatModel[]     ); });
        builder.addCase(Action.storeUserList,      (state, { payload: list }) => { storeModel(state, ModelType.User,      list as UserModel[]     ); });
        builder.addCase(Action.storeTaskList,      (state, { payload: list }) => { storeModel(state, ModelType.Task,      list as TaskModel[]     ); });

        /* eslint-enable max-len */
    },

    reducers : {

        setDebugModalOpen : (state, action : PA<boolean>) => {
            state.isDebugModalOpen = action.payload;
        },

        setClerkAuthStatus : (state, action : PA<ClerkAuthStatus>) => {
            state.clerkAuthStatus = action.payload;
        },

        resetToInitialState : () => InitialState,

        closeDialogPopup : (state, _action : PA) => {
            state.popup = {
                ...state.popup,
                actionPopupSuccess : null,
                isOpen             : false,
                title              : '',
            };
        },

        setCurrentUser : (state, action : PA<UserModel | null>) => {
            state.currentUserHash = action.payload?.hash ?? null;

            if (!action.payload?.hash)
                return;

            state.model = {
                ...state.model,
                User : {
                    ...state.model.User,
                    [action.payload?.hash] : action.payload,
                },
            };
        },
    },
});

// eslint-disable-next-line import/no-default-export
export default slice;
