import { DefaultPathwayDetailPage } from '../default/page/DefaultPathwayDetailPage';
import { DefaultContentDetailPage } from '../default/page/DefaultContentDetailPage';
import { DefaultAssetDetailPage   } from '../default/page/DefaultAssetDetailPage';
import { DefaultTranscript        } from '../default/DefaultTranscript';
import { DefaultMilestone         } from '../default/DefaultMilestone';
import { DefaultObjective         } from '../default/DefaultObjective';
import { DefaultCacheNode         } from '../default/DefaultCacheNode';
import { DefaultAnalysis          } from '../default/DefaultAnalysis';
import { DefaultDocument          } from '../default/DefaultDocument';
import { DefaultLocation          } from '../default/DefaultLocation';
import { DefaultActivity          } from '../default/DefaultActivity';
import { DefaultEventLog          } from '../default/DefaultEventLog';
import { DefaultPathway           } from '../default/DefaultPathway';
import { DefaultMessage           } from '../default/DefaultMessage';
import { DefaultContact           } from '../default/DefaultContact';
import { DefaultContent           } from '../default/DefaultContent';
import { DefaultAccount           } from '../default/DefaultAccount';
import { DefaultInvoice           } from '../default/DefaultInvoice';
import { DefaultPayment           } from '../default/DefaultPayment';
import { DefaultGeneric           } from '../default/DefaultGeneric';
import { DefaultReport            } from '../default/DefaultReport';
import { DefaultEvent             } from '../default/DefaultEvent';
import { DefaultAsset             } from '../default/DefaultAsset';
import { DefaultTask              } from '../default/DefaultTask';
import { DefaultUser              } from '../default/DefaultUser';
import { DefaultChat              } from '../default/DefaultChat';
import { DefaultJob               } from '../default/DefaultJob';
import { SpecialId                } from './SpecialId';
import { DialogModalStateType       } from '@app/constant/enum/ui/DialogModalStateTypeEnum';
import { TaskPositionType           } from '@app/constant/enum/ui/TaskPositionTypeEnum';
import { DialogPositionType         } from '@app/constant/enum/ui/DialogPositionTypeEnum';
import { TemplateHeaderPositionType } from '@app/constant/enum/ui/TemplateHeaderPositionTypeEnum';
import { TemplateNavbarPositionType } from '@app/constant/enum/ui/TemplateNavbarPositionTypeEnum';
import { AsideStatusType            } from '@app/constant/enum/ui/AsideStatusTypeEnum';
import { ModelType                  } from '@app/constant/enum/model/ModelTypeEnum';
import { CoreModel                  } from '@app/type/framework/core/CoreModel';
import { AppBrowsePage              } from '@app/type/page/browse/AppBrowsePage';
import { AppDetailPage              } from '@app/type/page/detail/AppDetailPage';
import { CoreAppRoot                } from '@app/type/framework/core/CoreAppRoot';
import { MessageModel               } from '@app/type/model/MessageModel';


const defaultmodelBrowsePage = <T extends CoreModel>(defaultModel : T) : AppBrowsePage<T> => {
    return {
        dialogModalStateType : DialogModalStateType.Closed,
        composeModelAnalysis : null,
        selectedModelHash    : SpecialId.Null,
        newModel             : defaultModel,
        model                : defaultModel,
    };
};

const defaultModelDetailPage = <T extends CoreModel>(defaultModel : T) : AppDetailPage<T> => {
    return {
        dialogModalStateType : DialogModalStateType.Closed,
        model                : defaultModel,
        isResearching        : false,
        chatText             : '',
    };
};

export const InitialState : CoreAppRoot = {

    admin : {
        chatCompletion : '[none]',
        chatPrompt     : '[none]',
    },

    researchControlList : {},

    serverActionLog : [],
    referenceList   : [],

    isDebugModalOpen : false,

    task : {
        taskHash : SpecialId.Null,
        position : TaskPositionType.FullScreen,
    },

    dialog : {
        dialogPosition : DialogPositionType.Middle,
        isFullscreen   : false,
        isOpen         : false,
    },

    confirmDelete : {
        modelType : null,
        modelHash : null,
    },

    isInitalizeComplete : false,
    clerkAuthStatus     : null,
    currentUserHash     : null,
    isAppLoaded         : false,
    isRecording         : false,
    errorList           : [],

    template : {
        isTemplateNavbarOpen : false,
        selectedNavbarItemId : null,
        navbarPosition       : TemplateNavbarPositionType.Left,
        headerPosition       : TemplateHeaderPositionType.Top,
        asideStatus          : AsideStatusType.Closed,
    },

    modelDetailPage : {
        [ModelType.Transcript] : defaultModelDetailPage(DefaultTranscript),
        [ModelType.Milestone ] : defaultModelDetailPage(DefaultMilestone ),
        [ModelType.CacheNode ] : defaultModelDetailPage(DefaultCacheNode ),
        [ModelType.Objective ] : defaultModelDetailPage(DefaultObjective ),
        [ModelType.Analysis  ] : defaultModelDetailPage(DefaultAnalysis  ),
        [ModelType.Document  ] : defaultModelDetailPage(DefaultDocument  ),
        [ModelType.Location  ] : defaultModelDetailPage(DefaultLocation  ),
        [ModelType.Activity  ] : defaultModelDetailPage(DefaultActivity  ),
        [ModelType.EventLog  ] : defaultModelDetailPage(DefaultEventLog  ),
        [ModelType.Message   ] : defaultModelDetailPage(DefaultMessage   ),
        [ModelType.Pathway   ] : DefaultPathwayDetailPage,
        [ModelType.Contact   ] : defaultModelDetailPage(DefaultContact   ),
        [ModelType.Generic   ] : defaultModelDetailPage(DefaultGeneric   ),
        [ModelType.Content   ] : DefaultContentDetailPage,
        [ModelType.Account   ] : defaultModelDetailPage(DefaultAccount   ),
        [ModelType.Invoice   ] : defaultModelDetailPage(DefaultInvoice   ),
        [ModelType.Payment   ] : defaultModelDetailPage(DefaultPayment   ),
        [ModelType.Report    ] : defaultModelDetailPage(DefaultReport    ),
        [ModelType.Event     ] : defaultModelDetailPage(DefaultEvent     ),
        [ModelType.Asset     ] : DefaultAssetDetailPage,
        [ModelType.Chat      ] : defaultModelDetailPage(DefaultChat      ),
        [ModelType.User      ] : defaultModelDetailPage(DefaultUser      ),
        [ModelType.Task      ] : defaultModelDetailPage(DefaultTask      ),
        [ModelType.Job       ] : defaultModelDetailPage(DefaultJob       ),
    },

    modelBrowsePage : {
        [ModelType.Transcript] : defaultmodelBrowsePage(DefaultTranscript),
        [ModelType.Milestone ] : defaultmodelBrowsePage(DefaultMilestone ),
        [ModelType.CacheNode ] : defaultmodelBrowsePage(DefaultCacheNode ),
        [ModelType.Objective ] : defaultmodelBrowsePage(DefaultObjective ),
        [ModelType.Analysis  ] : defaultmodelBrowsePage(DefaultAnalysis  ),
        [ModelType.Document  ] : defaultmodelBrowsePage(DefaultDocument  ),
        [ModelType.Location  ] : defaultmodelBrowsePage(DefaultLocation  ),
        [ModelType.Activity  ] : defaultmodelBrowsePage(DefaultActivity  ),
        [ModelType.Content   ] : defaultmodelBrowsePage(DefaultContent   ),
        [ModelType.Pathway   ] : defaultmodelBrowsePage(DefaultPathway   ),
        [ModelType.Message   ] : {
            ...defaultmodelBrowsePage(DefaultMessage),
            model : {
                ...DefaultMessage,
                subject : '',
                body    : '',
            } as MessageModel,
        },
        [ModelType.EventLog ] : defaultmodelBrowsePage(DefaultEventLog),
        [ModelType.Generic  ] : defaultmodelBrowsePage(DefaultGeneric ),
        [ModelType.Contact  ] : defaultmodelBrowsePage(DefaultContact ),
        [ModelType.Account  ] : defaultmodelBrowsePage(DefaultAccount ),
        [ModelType.Invoice  ] : defaultmodelBrowsePage(DefaultInvoice ),
        [ModelType.Payment  ] : defaultmodelBrowsePage(DefaultPayment ),
        [ModelType.Report   ] : defaultmodelBrowsePage(DefaultReport  ),
        [ModelType.Event    ] : defaultmodelBrowsePage(DefaultEvent   ),
        [ModelType.Asset    ] : defaultmodelBrowsePage(DefaultAsset   ),
        [ModelType.Chat     ] : defaultmodelBrowsePage(DefaultChat    ),
        [ModelType.User     ] : defaultmodelBrowsePage(DefaultUser    ),
        [ModelType.Task     ] : defaultmodelBrowsePage(DefaultTask    ),
        [ModelType.Job      ] : defaultmodelBrowsePage(DefaultJob     ),
    },

    research : {
    },

    popup : {
        actionPopupSuccess : null,
        isOpen             : false,
        title              : '[no title]',
    },

    contentPage : {
        dashboard : {
            naturalLanguageInput : '',
            weatherAnalysis      : '',
        },
    },

    transcriptList : [],

    model : {
        [ModelType.TextCompletion] : {},
        [ModelType.Relationship  ] : {},
        [ModelType.Transcript    ] : {},
        [ModelType.CacheNode     ] : {},
        [ModelType.Objective     ] : {},
        [ModelType.Milestone     ] : {},
        [ModelType.Analysis      ] : {},
        [ModelType.Document      ] : {},
        [ModelType.Location      ] : {},
        [ModelType.Activity      ] : {},
        [ModelType.EventLog      ] : {},
        [ModelType.Content       ] : {},
        [ModelType.Pathway       ] : {},
        [ModelType.Message       ] : {},
        [ModelType.Generic       ] : {},
        [ModelType.Contact       ] : {},
        [ModelType.Account       ] : {},
        [ModelType.Invoice       ] : {},
        [ModelType.Payment       ] : {},
        [ModelType.Report        ] : {},
        [ModelType.Event         ] : {},
        [ModelType.Asset         ] : {},
        [ModelType.Chat          ] : {},
        [ModelType.User          ] : {},
        [ModelType.Task          ] : {},
        [ModelType.Job           ] : {},
    },
} as const;
