import { RelationshipConfig } from '@app/type/framework/core/CoreModelRepo';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { type Saga } from '@app/type/framework/core/CoreSaga';
import { MakeChat } from '@app/constant/default/DefaultChat';
import { SharedAction } from '@app/shared/SharedAction';
import { notifications } from '@mantine/notifications';
import { RestApi as Api } from '@app/client/RestApi';
import { call, take } from 'typed-redux-saga';
import { takePayload } from '../SagaHelper';
import { Action } from '../action/index';
import { Browser } from '../Browser';
import { sagaDispatch } from '..';
import Router from 'next/router';
import slice from '../RootSlice';
import { Env } from '@app/Env';


function* sagaDownloadAssetFile() : Saga {

    const _asset = yield* takePayload(Action.downloadAssetFile);
}

function* sagaPushRoute() : Saga {

    const newRouteUrl = yield* takePayload(Action.pushRoute);

    Router.push(newRouteUrl);
}

function* sagaInitalize() : Saga {

    const clerkAuthStatus = yield* takePayload(slice.actions.setClerkAuthStatus);

    if (!clerkAuthStatus.isSignedIn || !clerkAuthStatus.isLoaded) {
        console.log('Not signed in, skipping initalization.')

        return;
    }

    const { modelStash } = yield* call(Api.initalize);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));
    yield* sagaDispatch(Action.initalizeComplete());
}

function* sagaNotification() : Saga {

    const notification = yield* takePayload(Action.notification);

    notifications.show({
        withBorder : true,
        loading    : notification.loading,
        message    : notification.message,
        title      : notification.title,
        autoClose  : notification.autoClose,
        color      : 'green',
    })
}

function* sagaUpdateModelStash() : Saga {

    // after the slice picked up the new value, sync with the server which should
    const { model, relationshipConfig } = yield* takePayload(Action.updateStashModelSync);

    // debugger;

    const { modelStash } = yield* call(Api.model.update, {
        relationshipConfig,
        model,
    });

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));
}

function* sagaLogout() : Saga {

    yield* take(Action.logout);
    yield* sagaDispatch(slice.actions.resetToInitialState());
    yield* call(Browser.signOut);
}

function* sagaError() : Saga {

    const err = yield* takePayload(Action.pushError);

    yield* sagaDispatch(Action.notification({
        message : err.message,
        title   : 'Error',
    }));

    sagaDispatch(slice.actions.setDebugModalOpen(true));
}

function* sagaStartStripePortal() : Saga {

    yield* take(Action.startStripePortal);

    const { checkoutSession } = yield* call(Api.plan.manage);

    yield call(Browser.redirect, checkoutSession.portalSessionUrl);
}

function* sagaEnsureCorrectSubdomain() : Saga {

    yield* take(Action.ensureCorrectSubdomain);

    const _currentSubdomain = Env.getSubdomain();
}

const _SampleMarkdown = `
    # Sample Markdown
`;

function * sagaAnalyzeWeatherEvent() : Saga {

    const _weatherEventAnalysis = yield* takePayload(Action.analyzeWeatherEvent);

    const weatherReportMarkdown = yield* call(Api.storm.weatherEventAnanlysis, _weatherEventAnalysis);

    yield sagaDispatch(Action.updateWeatherAnalysis(
        `${weatherReportMarkdown}
${_SampleMarkdown}`));
}

function* submitResearchText() : Saga {

    const { model, relationshipConfig } = yield* takePayload(Action.submitResearchText);

    yield* sagaDispatch(Action.notification({
        title   : `Researching ${model.title}`,
        message : 'This will take a few moments.',

    }));

    const { modelStash } = yield* call(Api.model.research, {
        relationshipConfig,
        model,
    });

    yield* sagaDispatch(Action.researchComplete(model.modelType));

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));

    yield* sagaDispatch(Action.notification({
        title   : `Finished Research on ${model.title}`,
        message : 'Thanks, bye.',
    }));
}

function* sagaPusherNotification() : Saga {
    const notification = yield* takePayload(SharedAction.pusherNotification);

    yield* sagaDispatch(Action.notification(notification));
}

function* contextMenuCopyToClipboard() : Saga {
    yield* takePayload(Action.contextMenuCopyToClipboard);

    yield* sagaDispatch(Action.notification({
        title : 'Copied to Clipboard',
    }));
}

function* contextMenuFurtherResearch() : Saga {
    const {
        researchQueryText, researchControlId,
        referenceModel, belongsToModel, chat,
    } = yield* takePayload(Action.contextMenuFurtherResearch);

    const relationshipConfig : RelationshipConfig = {
        [RT.ExtractedFrom] : chat,
        [RT.References   ] : referenceModel,
        [RT.BelongsTo    ] : belongsToModel,
    }

    // update the UI search box with the text
    yield* sagaDispatch(Action.updateResearchQueryText({
        researchQueryText : `Further Researching... ${researchQueryText}`,
        researchControlId,
    }));

    const { modelStash } = yield* call(Api.model.researchFollowup, {
        relationshipConfig,
        model : MakeChat({
            relatedContentHash : referenceModel.hash,
            query              : researchQueryText,
            groupHash          : chat?.groupHash,
        }),
    });

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));

    yield* sagaDispatch(Action.notification({
        title   : 'Finished Followup Research',
        message : 'Done.',
    }));
}

function* contextMenuShareContent() : Saga {
    const _model = yield* takePayload(Action.contextMenuShareContent);
}


export const AppSaga = {
    contextMenuFurtherResearch,
    contextMenuCopyToClipboard,
    sagaEnsureCorrectSubdomain,
    contextMenuShareContent,
    sagaAnalyzeWeatherEvent,
    sagaPusherNotification,
    sagaStartStripePortal,
    sagaDownloadAssetFile,
    sagaUpdateModelStash,
    submitResearchText,
    sagaNotification,
    sagaPushRoute,
    sagaInitalize,
    sagaLogout,
    sagaError,

    *[Symbol.iterator] () {
        yield this.contextMenuFurtherResearch,
        yield this.contextMenuCopyToClipboard,
        yield this.contextMenuShareContent,
        yield this.sagaAnalyzeWeatherEvent;
        yield this.sagaPusherNotification;
        yield this.sagaStartStripePortal;
        yield this.sagaDownloadAssetFile;
        yield this.sagaUpdateModelStash;
        yield this.submitResearchText;
        yield this.sagaNotification,
        yield this.sagaPushRoute;
        yield this.sagaInitalize;
        yield this.sagaLogout;
        yield this.sagaError;
    },
};
