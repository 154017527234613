import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { ContactModel } from '@app/type/model/ContactModel';
import { ContentModel } from '@app/type/model/ContentModel';


const toSelectItem = (recipients : ContactModel) : unknown => ({
    value : recipients.title ?? '[No Work Email]',
    label : recipients.title ?? '[No Work Email]',
});

const toSelectItemList = (recipients : ContactModel[]) : unknown[] =>
    recipients.map(toSelectItem);

export const findMostRecentModel = <T extends CoreModel>(
    modelList : T[],
) : T | null => {

    if(!modelList || modelList.length === 0)
        return null;

    return modelList.reduce(
        (previous, current) => (
            new Date(previous.updatedAt!) > new Date(current.updatedAt!)
        ) ? previous : current,
        {} as T,
    );
}

const modelToClipboardText = (model : CoreModel) : string => {
    switch (model.modelType) {
        case ModelType.Content: {
            const content = model as ContentModel;

            return `${content.text}\n\nRef: ${model.hash}`;
        }
    }

    return `[No Content]\n\nRef: ${model.hash}`;
}


export const ClientUtil = {
    modelToClipboardText,
    toSelectItemList,
    toSelectItem,
};
