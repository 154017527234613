import { CoreHash } from '@app/type/framework/core/CoreHash';
import { selectPagePathwayDetail } from './PageSelector';
import { createSelector } from '@reduxjs/toolkit';
import {
    selectLookupTranscript, selectLookupObjective, selectLookupMessage,
    selectLookupContact,    selectLookupAsset,     selectLookupEvent,
    selectLookupChat,       selectLookupTask,
} from './ListSelector';


export const selectPathwayDetailChatList = createSelector(
    selectPagePathwayDetail, selectLookupChat,
    (_selectPagePathwayDetail, _selectLookupChat) => {

        if (!_selectPagePathwayDetail.model?.id)
            return null;

        return [];
    },
);

export const selectPathwayDetailAssetList = createSelector(
    selectPagePathwayDetail, selectLookupAsset,
    (_selectPagePathwayDetail, _selectLookupAsset) => {

        if (!_selectPagePathwayDetail.model?.id)
            return null;

        return Object.values(_selectLookupAsset);
    },
);

export const selectPathwayDetailMessageList = createSelector(
    selectPagePathwayDetail, selectLookupMessage,
    (_selectPagePathwayDetail, _selectLookupMessage) => {

        if (!_selectPagePathwayDetail.model?.id)
            return null;

        return Object.values(_selectLookupMessage);
    },
);

export const selectPathwayDetailContactList = createSelector(
    selectPagePathwayDetail, selectLookupContact,
    (_selectPagePathwayDetail, _selectLookupContact) => {

        if (!_selectPagePathwayDetail.model?.id)
            return null;

        return Object.values(_selectLookupContact);
    },
);

export const selectPathwayDetailEventList = createSelector(
    selectPagePathwayDetail, selectLookupEvent,
    (_selectPagePathwayDetail, _selectLookupEvent) => {

        if (!_selectPagePathwayDetail.model?.id)
            return null;

        return Object.values(_selectLookupEvent);
    },
);


export const selectPathwayDetailTaskList = createSelector(
    selectPagePathwayDetail, selectLookupTask,
    (_selectPagePathwayDetail, _selectLookupTask) => {

        if (!_selectPagePathwayDetail.model?.id)
            return null;

        return Object.values(_selectLookupTask);
    },
);


export const selectPathwayDetailChat = createSelector(
    selectPagePathwayDetail, selectLookupChat,
    (_selectPagePathwayDetail, _selectLookupChat) => {

        if (!_selectPagePathwayDetail.selectedChatHash) return null;

        return _selectLookupChat[_selectPagePathwayDetail.selectedChatHash];
    },
);

export const selectPathwayDetailChatObjectiveList = createSelector(
    selectPathwayDetailChat, selectLookupObjective,
    (matterDetailChat, lookupObjective) => {

        if (!matterDetailChat) return null;

        const { ObjectiveList } = matterDetailChat;

        if (!ObjectiveList) return null;

        return (ObjectiveList as unknown as CoreHash[])
            .map(objective =>
                lookupObjective[objective!],
            );
    },
);

export const selectPathwayDetailChatTranscriptList = createSelector(
    selectPathwayDetailChat, selectLookupTranscript,
    (matterDetailChat, lookupTranscript) => {

        if (!matterDetailChat) return null;

        const { TranscriptList } = matterDetailChat;

        if (!TranscriptList) return null;

        return (TranscriptList as unknown as CoreHash[])
            .map(transcript =>
                lookupTranscript[transcript!],
            );
    },
);
