import { MakePathway } from '@app/constant/default/DefaultPathway';
import { type Saga } from '@app/type/framework/core/CoreSaga';
import { CoreId } from '@app/type/framework/core/CoreId';
import { delay, sagaDispatch } from '@app/client';
import { takePayload } from '../SagaHelper';
import { Action } from '../action';


const PingSecondsInterval = 3;

function* sagaIndexStatusCheck() : Saga {

    const matterHash = yield* takePayload(Action.monitorSingleServePage);

    if (!matterHash)
        throw new Error('Invalid matter hash looking up document');

    console.log('Looking up the matter ', matterHash);

    yield sagaDispatch(Action.pathwayGet(
        MakePathway({
            hash : matterHash,
            id   : matterHash as unknown as CoreId,
        }),
    ));

    while (true) {
        console.log('Still waiting...');

        yield delay(1000 * PingSecondsInterval);
    }
}

export const SingleServeSaga = {
    sagaIndexStatusCheck,

    *[Symbol.iterator] () {
        yield this.sagaIndexStatusCheck;
    },
};
