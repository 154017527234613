
export enum ContentHeaderLevel {
    Unknown = 'Unknown',
    H1      = 'H1',
    H2      = 'H2',
    H3      = 'H3',
    H4      = 'H4',
    H5      = 'H5',
    H6      = 'H6',
}
