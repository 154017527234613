export enum ChatContentType {
    Conclusion = 'Conclusion',
    CodeBlock  = 'CodeBlock',
    Paragraph  = 'Paragraph',
    Content    = 'Content',
    Unknown    = 'Unknown',
    Header     = 'Header',
    Title      = 'Title',
    Image      = 'Image',
    Quote      = 'Quote',
    List       = 'List',
    Link       = 'Link',
}
