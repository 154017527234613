import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { CoreModel } from '@app/type/framework/core/CoreModel';


export const DefaultCoreModel : CoreModel = {

    description : '',
    modelType   : ModelType.Generic,
    createdAt   : null,
    updatedAt   : null,
    createdBy   : null,
    debug       : '',
    title       : '',
    hash        : null,
    id          : null,
} as const;

export const MakeCoreModel = (
    overrides : Partial<CoreModel>,
) : CoreModel => ({
    ...DefaultCoreModel,
    ...overrides,
    modelType : ModelType.Generic,
});
