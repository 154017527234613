import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { CacheNodeModel } from '@app/type/model/CacheNodeModel';


export const DefaultCacheNode : CacheNodeModel = {

    ...DefaultCoreModel,

    modelType : ModelType.CacheNode,

    expiresAt : null,
    original  : null,
    value     : null,
    key       : '',
} as const;

export const MakeCacheNode = (
    overrides : Partial<CacheNodeModel>,
) : CacheNodeModel => ({
    ...DefaultCacheNode,
    ...overrides,
    modelType : ModelType.CacheNode,
});
