import { ContentHeaderLevel } from '@app/constant/enum/chat/ContentHeaderLevel';
import { ContentListStyle } from '@app/constant/enum/chat/ContentListStyle';
import { ChatContentType } from '@app/constant/enum/chat/ChatContentType';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { ContentModel } from '@app/type/model/ContentModel';
import { DefaultCoreModel } from './DefaultCoreModel';


export const DefaultContent : ContentModel = {

    ...DefaultCoreModel,

    chatContentType : ChatContentType.Unknown,

    modelType   : ModelType.Content,
    contentType : ChatContentType.Unknown,

    order : 0,

    language : '',
    content  : '',
    unknown  : '',
    style    : ContentListStyle.Ordered,
    level    : ContentHeaderLevel.Unknown,
    items    : [],
    text     : '',
    code     : '',
    url      : '',
    alt      : '',

    auditLog : '',

} as const;

export const MakeContent = (
    overrides : ContentModel,
) : ContentModel => ({
    ...DefaultContent,
    ...overrides,
});
