import { RelationshipModel } from '@app/type/model/RelationshipModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { selectState, countAttachedToDocument } from './common';
import { DocumentModel } from '@app/type/model/DocumentModel';
import { findMostRecentModel } from '../ClientUtil';
import { createSelector } from '@reduxjs/toolkit';
import {
    selectLookupRelationship, selectLookupDocument,
    selectLookupLocation, selectLookupContact,
    selectLookupAsset,
} from './ListSelector';


/* eslint-disable max-len */

export const selectModelBrowsePage = createSelector(selectState, _selectState => _selectState.modelBrowsePage);

export const selectPageRelationship = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Relationship );
export const selectPageTranscript   = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Transcript);
export const selectPageCacheNode    = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.CacheNode );
export const selectPageObjective    = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Objective );
export const selectPageMilestone    = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Milestone );
export const selectPageAnalysis     = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Analysis  );
export const selectPageLocation     = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Location  );
export const selectPageActivity     = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Activity  );
export const selectPageMessage      = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Message   );
export const selectPageGeneric      = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Generic   );
export const selectPageContact      = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Contact   );
export const selectPageAccount      = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Account   );
export const selectPageInvoice      = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Invoice   );
export const selectPagePathway      = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Pathway   );
export const selectPagePayment      = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Payment   );
export const selectPageReport       = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Report    );
export const selectPageAsset        = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Asset     );
export const selectPageEvent        = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Event     );
export const selectPageUser         = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.User      );
export const selectPageTask         = createSelector(selectModelBrowsePage, _selectModelBrowsePage => _selectModelBrowsePage.Task      );


export const selectPageTranscriptHash = createSelector(selectPageTask, _selectPageTranscript => _selectPageTranscript.selectedModelHash);
export const selectPageCacheNodeHash  = createSelector(selectPageTask, _selectPageCacheNode  => _selectPageCacheNode.selectedModelHash );
export const selectPageObjectiveHash  = createSelector(selectPageTask, _selectPageObjective  => _selectPageObjective.selectedModelHash );
export const selectPageAnalysisHash   = createSelector(selectPageTask, _selectPageAnalysis   => _selectPageAnalysis.selectedModelHash  );
export const selectPageLocationHash   = createSelector(selectPageTask, _selectPageLocation   => _selectPageLocation.selectedModelHash  );
export const selectPageActivityHash   = createSelector(selectPageTask, _selectPageActivity   => _selectPageActivity.selectedModelHash  );
export const selectPageMessageHash    = createSelector(selectPageTask, _selectPageMessage    => _selectPageMessage.selectedModelHash   );
export const selectPageGenericHash    = createSelector(selectPageTask, _selectPageGeneric    => _selectPageGeneric.selectedModelHash   );
export const selectPageContactHash    = createSelector(selectPageTask, _selectPageContact    => _selectPageContact.selectedModelHash   );
export const selectPageAccountHash    = createSelector(selectPageTask, _selectPageAccount    => _selectPageAccount.selectedModelHash   );
export const selectPageInvoiceHash    = createSelector(selectPageTask, _selectPageInvoice    => _selectPageInvoice.selectedModelHash   );
export const selectPagePaymentHash    = createSelector(selectPageTask, _selectPagePayment    => _selectPagePayment.selectedModelHash   );
export const selectPageReportHash     = createSelector(selectPageTask, _selectPageReport     => _selectPageReport.selectedModelHash    );
export const selectPageAssetHash      = createSelector(selectPageTask, _selectPageAsset      => _selectPageAsset.selectedModelHash     );
export const selectPageEventHash      = createSelector(selectPageTask, _selectPageEvent      => _selectPageEvent.selectedModelHash     );
export const selectPageTaskHash       = createSelector(selectPageTask, _selectPageTask       => _selectPageTask.selectedModelHash      );
export const selectPageUserHash       = createSelector(selectPageTask, _selectPageUser       => _selectPageUser.selectedModelHash      );

export const selectPageRelationshipCompose = createSelector(selectPageRelationship, _selectPageRelationship => _selectPageRelationship.model);
export const selectPageTranscriptCompose   = createSelector(selectPageTranscript,   _selectPageTranscript   => _selectPageTranscript.model  );
export const selectPageCacheNodeCompose    = createSelector(selectPageCacheNode,    _selectPageCacheNode    => _selectPageCacheNode.model   );
export const selectPageObjectiveCompose    = createSelector(selectPageObjective,    _selectPageObjective    => _selectPageObjective.model   );
export const selectPageAnalysisCompose     = createSelector(selectPageAnalysis,     _selectPageAnalysis     => _selectPageAnalysis.model    );
export const selectPageLocationCompose     = createSelector(selectPageLocation,     _selectPageLocation     => _selectPageLocation.model    );
export const selectPageActivityCompose     = createSelector(selectPageActivity,     _selectPageActivity     => _selectPageActivity.model    );
export const selectPageMessageCompose      = createSelector(selectPageMessage,      _selectPageMessage      => _selectPageMessage.model     );
export const selectPageGenericCompose      = createSelector(selectPageGeneric,      _selectPageGeneric      => _selectPageGeneric.model     );
export const selectPageContactCompose      = createSelector(selectPageContact,      _selectPageContact      => _selectPageContact.model     );
export const selectPageAccountCompose      = createSelector(selectPageAccount,      _selectPageAccount      => _selectPageAccount.model     );
export const selectPageInvoiceCompose      = createSelector(selectPageInvoice,      _selectPageInvoice      => _selectPageInvoice.model     );
export const selectPagePaymentCompose      = createSelector(selectPagePayment,      _selectPagePayment      => _selectPagePayment.model     );
export const selectPageReportCompose       = createSelector(selectPageReport,       _selectPageReport       => _selectPageReport.model      );
export const selectPageAssetCompose        = createSelector(selectPageAsset,        _selectPageAsset        => _selectPageAsset.model       );
export const selectPageEventCompose        = createSelector(selectPageEvent,        _selectPageEvent        => _selectPageEvent.model       );
export const selectPageTaskCompose         = createSelector(selectPageTask,         _selectPageTask         => _selectPageTask.model        );
export const selectPageUserCompose         = createSelector(selectPageUser,         _selectPageUser         => _selectPageUser.model        );

// export const selectModelDetailPage = createSelector(selectState, _selectState => _selectState.modelDetailPage);

export const selectModelDetailPage = createSelector(selectState, _selectState => _selectState.modelDetailPage);

export const selectPageRelationshipDetail = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Relationship);
export const selectPageTranscriptDetail   = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Transcript  );
export const selectPageMilestoneDetail    = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Milestone   );
export const selectPageCacheNodeDetail    = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.CacheNode   );
export const selectPageObjectiveDetail    = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Objective   );
export const selectPageAnalysisDetail     = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Analysis    );
export const selectPageActivityDetail     = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Activity    );
export const selectPageMessageDetail      = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Message     );
export const selectPageGenericDetail      = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Generic     );
export const selectPageAccountDetail      = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Account     );
export const selectPageInvoiceDetail      = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Invoice     );
export const selectPagePaymentDetail      = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Payment     );
export const selectPagePathwayDetail      = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Pathway     );
export const selectPageReportDetail       = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Report      );
export const selectPageEventDetail        = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Event);
export const selectPageUserDetail         = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.User );
export const selectPageTaskDetail         = createSelector(selectModelDetailPage, _selectModelDetailPage => _selectModelDetailPage.Task );

export const selectPageAssetDetail = createSelector(
    selectModelDetailPage,
    selectLookupRelationship,
    selectLookupDocument,
    selectLookupAsset,
    (modelDetailPage, lookupRelationship, lookupDocument, lookupAsset) => {

        const assetHash = modelDetailPage.Asset.model?.hash || '';

        if(!assetHash)
            return {
                ...modelDetailPage.Asset,
                relationshipList : [],
                documentCount    : 0,
                document         : null,
                lookupAsset,
            };

        // when the page first loads, redux isnt updated yet with page level
        // data. The page looks up its asset, updates redux and then the
        // hash if fulled in here. From there we can retun the asset
        // and document info

        const attachedDocumentRelationshipList = Object.values(lookupRelationship)
            .filter(relationship => {
                return relationship.sourceModelType  === ModelType.Document
                    && relationship.relationshipType === RT.AttachedTo
                    && relationship.destModelType    === ModelType.Asset
                    && relationship.destModelHash    === assetHash
            });

        const model = findMostRecentModel<RelationshipModel>(attachedDocumentRelationshipList);

        const documentCount = countAttachedToDocument(attachedDocumentRelationshipList);

        let document : DocumentModel | null = null;

        if(model)
            document = lookupDocument[model.sourceModelId!];

        return {
            ...modelDetailPage.Asset,
            relationshipList : attachedDocumentRelationshipList,
            lookupAsset,
            document,
            documentCount,
        };
    },
);

export const selectPageContactDetail = createSelector(
    selectModelDetailPage, selectLookupContact,
    (modelDetailPage, lookupContact) => {

        const contactHash = modelDetailPage.Contact.model?.hash || '';

        if(!contactHash)
            return {
                ...modelDetailPage.Contact,
                lookupContact,
            };

        return {
            ...modelDetailPage.Contact,
            lookupContact,
        };
    },
);

export const selectPageLocationDetail = createSelector(
    selectModelDetailPage, selectLookupLocation,
    (modelDetailPage, lookupLocation) => {

        const locationHash = modelDetailPage.Location.model?.hash || '';

        if(!locationHash)
            return {
                ...modelDetailPage.Location,
                lookupLocation,
            };

        return {
            ...modelDetailPage.Location,
            lookupLocation,
        };
    },
);

/* eslint-enable max-len */
