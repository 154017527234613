import { WeatherEventAnalysis } from '@app/type/control/WeatherEventAnalysis';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ApiResultPlanManage } from '@app/type/api/ApiResultPlanManage';
import { RelationshipConfig } from '@app/type/model/RelationshipConfig';
import { ActionResearchModel } from '@app/type/ActionResearchModel';
import { TranscriptModel } from '@app/type/model//TranscriptModel';
import { ApiModelAndStash } from '@app/type/api/ApiModelAndStash';
import { MilestoneModel } from '@app/type/model//MilestoneModel';
import { ObjectiveModel } from '@app/type/model//ObjectiveModel';
import { ActionUpdateModel } from '@app/type/ActionUpdateModel';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { ActivityModel } from '@app/type/model//ActivityModel';
import { LocationModel } from '@app/type/model//LocationModel';
import { SpecialId } from '@app/constant/framework/SpecialId';
import { CoreFile } from '@app/type/framework/core/CoreFile';
import { CoreHash } from '@app/type/framework/core/CoreHash';
import { AccountModel } from '@app/type/model//AccountModel';
import { ContactModel } from '@app/type/model//ContactModel';
import { MessageModel } from '@app/type/model//MessageModel';
import { PathwayModel } from '@app/type/model//PathwayModel';
import { AssetModel } from '@app/type/model//AssetModel';
import { ApiAuthClio } from '@app/type/api/ApiAuthClio';
import { TaskModel } from '@app/type/model//TaskModel';
import { UserModel } from '@app/type/model//UserModel';
import { ChatModel } from '@app/type/model/ChatModel';
import { JobModel } from '@app/type/model/JobModel';
import { P } from '@app/type/framework/data/P';
import axios from 'axios';


axios.defaults.withCredentials = true;

export const RestApi = {
    admin : {
        extractModelEntities : async (model : CoreModel) : P<ApiModelAndStash<CoreModel>> =>
            (await axios.post('/api/admin/extract-model-entities', { model }))?.data?.data,

        submitChatPrompt : async (chatPrompt : string) : P<string> =>
            (await axios.post('/api/admin/chat', { chatPrompt }))?.data?.data,

        submitResearch : async (coreModel : CoreModel, chatText : string) : P<ApiModelAndStash<ChatModel>> =>
            (await axios.put('/api/admin/submit-research', {
                chatText,
                model : {
                    ...DefaultCoreModel,
                    modelType : coreModel.modelType,
                    hash      : coreModel.hash,
                    id        : coreModel.hash,
                },
            }))?.data?.data,
    },

    cron : {
        phase1_documentOCR : async () : P<boolean> =>
            (await axios.get('/api/cron/phase-1-document-ocr'))?.data?.data,

        phase2_documentExtract : async () : P<string> =>
            (await axios.get('/api/cron/phase-2-document-research'))?.data?.data,
    },

    storm : {
        weatherEventAnanlysis : async (weatherEventAnalysis : WeatherEventAnalysis) : P<string> =>
            (await axios.post('/api/storm/weather-event-analysis', { weatherEventAnalysis }))?.data?.data,
    },

    plan : {
        manage : async () : P<ApiResultPlanManage> =>
            (await axios.get('/api/plan/manage'))
                ?.data?.data,
    },

    initalize : async () : P<ApiModelAndStash<UserModel>> =>
        (await axios.get('/api/initalize'))
            ?.data?.data,

    auth : {
        clio : async () : P<ApiAuthClio> =>
            (await axios.post('/api/auth/clio'))
                ?.data?.data,
    },

    model : {
        update : async ({
            model, relationshipConfig,
        } : ActionUpdateModel) : P<ApiModelAndStash<CoreModel>> =>
            (await axios.put('/api/model/update', {
                model, relationshipConfig,
            }))?.data?.data,

        research : async ({
            model, relationshipConfig,
        } : ActionResearchModel) : P<ApiModelAndStash<CoreModel>> =>
            (await axios.put('/api/model/research', {
                model, relationshipConfig,
            }))?.data?.data,

        researchFollowup : async ({
            model, relationshipConfig,
        } : ActionResearchModel) : P<ApiModelAndStash<CoreModel>> =>
            (await axios.put('/api/model/research-followup', {
                model, relationshipConfig,
            }))?.data?.data,

    },

    pathway : {
        upload : async (coreHash : CoreHash, coreFile : CoreFile) : P<ApiModelAndStash<LocationModel>> =>
            (await axios.post(`/api/pathway/upload/${coreHash}`, coreFile))?.data?.data,

        create : async (pathway : Partial<PathwayModel> | Partial<CoreModel>) : P<ApiModelAndStash<PathwayModel>> =>
            (await axios.post('/api/pathway/create', { pathway }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<PathwayModel>> =>
            (await axios.get(`/api/pathway/${hash}`))?.data?.data,

        update : async (pathway : Partial<PathwayModel> | CoreModel) : P<ApiModelAndStash<PathwayModel>> =>
            (await axios.put(`/api/pathway/update/${pathway.hash}`, { pathway }))?.data?.data,

        publish : async (pathway : PathwayModel) : P<ApiModelAndStash<PathwayModel>> =>
            (await axios.put(`/api/pathway/publish/${pathway.hash}`, { pathway }))?.data?.data,

        start : async (pathway : PathwayModel) : P<ApiModelAndStash<PathwayModel>> =>
            (await axios.post(`/api/pathway/start/${pathway.hash}`, { pathway }))?.data?.data,

        generatePatent : async (pathwayHash : CoreHash) : P<ApiModelAndStash<PathwayModel>> =>
            (await axios.post(`/api/pathway/generate-patent/${pathwayHash}`))?.data?.data,


    },

    milestone : {
        upload : async (coreHash : CoreHash, coreFile : CoreFile) : P<ApiModelAndStash<LocationModel>> =>
            (await axios.post(`/api/milestone/upload/${coreHash}`, coreFile))?.data?.data,

        create : async (
            milestone : Partial<MilestoneModel> | Partial<CoreModel>,
            relationshipConfig : RelationshipConfig,
        ) : P<ApiModelAndStash<MilestoneModel>> =>
            (await axios.post('/api/milestone/create', { milestone, relationshipConfig }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<MilestoneModel>> =>
            (await axios.get(`/api/milestone/${hash}`))?.data?.data,

        update : async (milestone : Partial<MilestoneModel> | CoreModel) : P<ApiModelAndStash<MilestoneModel>> =>
            (await axios.put(`/api/milestone/update/${milestone.hash}`, { milestone }))?.data?.data,
    },

    activity : {
        upload : async (coreHash : CoreHash, coreFile : CoreFile) : P<ApiModelAndStash<ActivityModel>> =>
            (await axios.post(`/api/activity/upload/${coreHash}`, coreFile))?.data?.data,

        analysis : async (activity : Partial<ActivityModel> | CoreModel) : P<ApiModelAndStash<ActivityModel>> =>
            (await axios.put(`/api/activity/anslysis/${activity.hash ?? SpecialId.CreateHash}`, { activity }))?.data?.data,

        create : async (activity : Partial<ActivityModel> | Partial<CoreModel>) : P<ApiModelAndStash<ActivityModel>> =>
            (await axios.post('/api/activity/create', { activity }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<ActivityModel>> =>
            (await axios.put(`/api/activity/delete/${hash}`, { activity : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<ActivityModel>> =>
            (await axios.get(`/api/activity/${hash}`))?.data?.data,

        update : async (activity : Partial<ActivityModel> | CoreModel) : P<ApiModelAndStash<ActivityModel>> =>
            (await axios.put(`/api/activity/update/${activity.hash}`, { activity }))?.data?.data,
    },

    message : {
        upload : async (coreHash : CoreHash, coreFile : CoreFile) : P<ApiModelAndStash<MessageModel>> =>
            (await axios.post(`/api/message/upload/${coreHash}`, coreFile))?.data?.data,

        create : async (message : Partial<MessageModel> | Partial<CoreModel>) : P<ApiModelAndStash<MessageModel>> =>
            (await axios.post('/api/message/create', { message }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<MessageModel>> =>
            (await axios.put(`/api/message/delete/${hash}`, { message : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<MessageModel>> =>
            (await axios.get(`/api/message/${hash}`))?.data?.data,

        update : async (message : Partial<MessageModel> | CoreModel) : P<ApiModelAndStash<MessageModel>> =>
            (await axios.put(`/api/message/update/${message.hash}`, { message }))?.data?.data,
    },


    contact : {
        upload : async (coreHash : CoreHash, coreFile : CoreFile) : P<ApiModelAndStash<ContactModel>> =>
            (await axios.post(`/api/contact/upload/${coreHash}`, coreFile))?.data?.data,

        create : async (contact : Partial<ContactModel> | Partial<CoreModel>) : P<ApiModelAndStash<ContactModel>> =>
            (await axios.post('/api/contact/create', { contact }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<ContactModel>> =>
            (await axios.put(`/api/contact/delete/${hash}`, { contact : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<ContactModel>> =>
            (await axios.get(`/api/contact/${hash}`))?.data?.data,

        update : async (contact : Partial<ContactModel> | CoreModel) : P<ApiModelAndStash<ContactModel>> =>
            (await axios.put(`/api/contact/update/${contact.hash}`, { contact }))?.data?.data,
    },

    account : {
        create : async (account : Partial<AccountModel> | Partial<CoreModel>) : P<ApiModelAndStash<AccountModel>> =>
            (await axios.post('/api/account/create', { account }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<AccountModel>> =>
            (await axios.put(`/api/account/delete/${hash}`, { account : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<AccountModel>> =>
            (await axios.get(`/api/account/${hash}`))?.data?.data,

        update : async (account : Partial<AccountModel> | CoreModel) : P<ApiModelAndStash<AccountModel>> =>
            (await axios.put(`/api/account/update/${account.hash}`, { account }))?.data?.data,
    },


    chat : {
        analysis : async (chat : Partial<ChatModel> | CoreModel) : P<ApiModelAndStash<ChatModel>> =>
            (await axios.put(`/api/chat/anslysis/${chat.hash ?? SpecialId.CreateHash}`, { chat }))?.data?.data,

        create : async (chat : Partial<ChatModel> | Partial<CoreModel>) : P<ApiModelAndStash<ChatModel>> =>
            (await axios.post('/api/chat/create', { chat }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<ChatModel>> =>
            (await axios.put(`/api/hash/anslysis/${hash}`, { chat : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<ChatModel>> =>
            (await axios.get(`/api/chat/${hash}`))?.data?.data,

        update : async (chat : Partial<ChatModel> | CoreModel) : P<ApiModelAndStash<ChatModel>> =>
            (await axios.put(`/api/chat/anslysis/${chat.hash ?? SpecialId.CreateHash}`, { chat }))?.data?.data,
    },

    objective : {
        create : async (objective : Partial<ObjectiveModel> | Partial<CoreModel>) : P<ApiModelAndStash<ObjectiveModel>> =>
            (await axios.post('/api/objective/create', { objective }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<ObjectiveModel>> =>
            (await axios.put(`/api/objective/delete/${hash}`, { objective : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<ObjectiveModel>> =>
            (await axios.get(`/api/objective/${hash}`))?.data?.data,

        update : async (objective : Partial<ObjectiveModel> | CoreModel) : P<ApiModelAndStash<ObjectiveModel>> =>
            (await axios.put(`/api/objective/update/${objective.hash ?? SpecialId.CreateHash}`, { objective }))?.data?.data,
    },

    transcript : {
        create : async (transcript : Partial<TranscriptModel> | Partial<CoreModel>) : P<ApiModelAndStash<ObjectiveModel>> =>
            (await axios.post('/api/transcript/create', { transcript }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<TranscriptModel>> =>
            (await axios.put(`/api/transcript/delete/${hash}`, { transcript : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<TranscriptModel>> =>
            (await axios.get(`/api/transcript/${hash}`))?.data?.data,

        update : async (transcript : Partial<TranscriptModel> | CoreModel) : P<ApiModelAndStash<TranscriptModel>> =>
            (await axios.put(`/api/transcript/update/${transcript.hash ?? SpecialId.CreateHash}`, { transcript }))?.data?.data,
    },

    asset : {
        upload : async (coreHash : CoreHash, coreFile : CoreFile) : P<ApiModelAndStash<AssetModel>> =>
            (await axios.post(`/api/asset/upload/${coreHash}`, coreFile))?.data?.data,

        create : async (asset : Partial<AssetModel> | Partial<CoreModel>) : P<ApiModelAndStash<AssetModel>> =>
            (await axios.post('/api/asset/create', { asset }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<AssetModel>> =>
            (await axios.put(`/api/asset/delete/${hash}`, { asset : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<AssetModel>> =>
            (await axios.get(`/api/asset/${hash}`))?.data?.data,

        update : async (asset : Partial<AssetModel> | CoreModel) : P<ApiModelAndStash<AssetModel>> =>
            (await axios.put(`/api/asset/update/${asset.hash}`, { asset }))?.data?.data,

        statusCheck : async (asset : AssetModel) : P<ApiModelAndStash<AssetModel>> =>
            (await axios.post('/api/asset/status', { asset }))?.data?.data,
    },

    user : {
        create : async (user : Partial<UserModel> | Partial<CoreModel>) : P<ApiModelAndStash<UserModel>> =>
            (await axios.post('/api/user/create', { user }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<UserModel>> =>
            (await axios.put(`/api/user/delete/${hash}`, { user : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<UserModel>> =>
            (await axios.get(`/api/user/${hash}`))?.data?.data,

        update : async (user : Partial<UserModel> | CoreModel) : P<ApiModelAndStash<UserModel>> =>
            (await axios.put(`/api/user/update/${user.hash}`, { user }))?.data?.data,
    },

    task : {
        upload : async (coreHash : CoreHash, coreFile : CoreFile) : P<ApiModelAndStash<TaskModel>> =>
            (await axios.post(`/api/task/upload/${coreHash}`, coreFile))?.data?.data,

        create : async (
            task : Partial<TaskModel> | Partial<CoreModel>,
            relationshipConfig : RelationshipConfig,
        ) : P<ApiModelAndStash<TaskModel>> =>
            (await axios.post('/api/task/create', { task, relationshipConfig }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<TaskModel>> =>
            (await axios.put(`/api/task/delete/${hash}`, { task : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<TaskModel>> =>
            (await axios.get(`/api/task/${hash}`))?.data?.data,

        update : async (task : Partial<TaskModel> | CoreModel) : P<ApiModelAndStash<TaskModel>> =>
            (await axios.put(`/api/task/update/${task.hash}`, { task }))?.data?.data,
    },

    job : {
        create : async (job : Partial<JobModel> | Partial<CoreModel>) : P<ApiModelAndStash<JobModel>> =>
            (await axios.post('/api/job/create', { job }))?.data?.data,

        delete : async (hash : CoreHash) : P<ApiModelAndStash<JobModel>> =>
            (await axios.put(`/api/job/delete/${hash}`, { job : hash }))?.data?.data,

        get : async (hash : CoreHash) : P<ApiModelAndStash<JobModel>> =>
            (await axios.get(`/api/job/${hash}`))?.data?.data,

        update : async (job : Partial<JobModel> | CoreModel) : P<ApiModelAndStash<JobModel>> =>
            (await axios.put(`/api/job/update/${job.hash}`, { job }))?.data?.data,
    },
};
