import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ActivityType } from '@app/constant/enum/model/ActivityTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { ActivityModel } from '@app/type/model/ActivityModel';


export const DefaultActivity : ActivityModel = {

    ...DefaultCoreModel,

    activityType : ActivityType.Generic,
    modelType    : ModelType.Activity,
} as const;

export const MakeActivity = (
    overrides : Partial<ActivityModel>,
) : ActivityModel => ({
    ...DefaultActivity,
    ...overrides,
    modelType : ModelType.Activity,
});
