import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { AnalysisModel } from '@app/type/model/AnalysisModel';
import { AnalysisType } from '../enum/model/AnalysisTypeEnum';


export const DefaultAnalysis : AnalysisModel = {

    ...DefaultCoreModel,

    analysisType : AnalysisType.Generic,
    modelType    : ModelType.Analysis,

    rawAnalysis : '',
} as const;

export const MakeAnalysis = (
    overrides : Partial<AnalysisModel>,
) : AnalysisModel => ({
    ...DefaultAnalysis,
    ...overrides,
    modelType : ModelType.Analysis,
});
