import { PathwayDetailPage } from '@app/type/page/detail/PathwayDetailPage';
import { DefaultDetailPage } from './DefaultDetailPage';


export const DefaultPathwayDetailPage : PathwayDetailPage = {

    ...DefaultDetailPage,

    selectedMilestoneHash : null,
    selectedAssetHash     : null,
    selectedChatHash      : null,
    isResearching         : false,
    model                 : null,
} as const;
