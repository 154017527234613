import { createSelector } from '@reduxjs/toolkit';
import { selectLookupUser } from './ListSelector';
import { selectState } from './common';


export const selectIsInitalizeComplete = createSelector(selectState, _selectState => _selectState.isInitalizeComplete);
export const selectResearchControlList = createSelector(selectState, _selectState => _selectState.researchControlList);
export const selectIsDebugModalOpen    = createSelector(selectState, _selectState => _selectState.isDebugModalOpen   );
export const selectCurrentUserHash     = createSelector(selectState, _selectState => _selectState.currentUserHash    );
export const selectConfirmDelete       = createSelector(selectState, _selectState => _selectState.confirmDelete      );
export const selectIsAppLoaded         = createSelector(selectState, _selectState => _selectState.isAppLoaded        );
export const selectIsRecording         = createSelector(selectState, _selectState => _selectState.isRecording        );
export const selectContentPage         = createSelector(selectState, _selectState => _selectState.contentPage        );
export const selectAuthStatus          = createSelector(selectState, _selectState => _selectState.clerkAuthStatus    );
export const selectErrorList           = createSelector(selectState, _selectState => _selectState.errorList          );
export const selectTemplate            = createSelector(selectState, _selectState => _selectState.template           );
export const selectResearch            = createSelector(selectState, _selectState => _selectState.research           );
export const selectDialog              = createSelector(selectState, _selectState => _selectState.dialog             );
export const selectPopup               = createSelector(selectState, _selectState => _selectState.popup              );
export const selectAdmin               = createSelector(selectState, _selectState => _selectState.admin              );
export const selectTask                = createSelector(selectState, _selectState => _selectState.task               );

export const selectTemplateAsideStatus = createSelector(
    selectTemplate,
    _selectTemplate => _selectTemplate.asideStatus,
);

export const selectIsTemplateNavbarOpen = createSelector(
    selectTemplate,
    _selectTemplate => _selectTemplate.isTemplateNavbarOpen,
);

export const selectContentPageDashboard = createSelector(selectContentPage, _selectContentPage => _selectContentPage.dashboard);

export const selectPopupIsOpen = createSelector(selectPopup, _selectPopup => _selectPopup.isOpen);
export const selectPopupTitle  = createSelector(selectPopup, _selectPopup => _selectPopup.title );


export const selectCurrentUser = createSelector(
    selectLookupUser, selectCurrentUserHash,
    (_selectLookupUser, _selectCurrentUserHash) => {

        if (!_selectCurrentUserHash) return null;

        return _selectLookupUser[_selectCurrentUserHash];
    },
);
