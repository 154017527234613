import { type Saga } from '@app/type/framework/core/CoreSaga';
import { RestApi as Api } from '@app/client/RestApi';
import { takePayload } from '../SagaHelper';
import { sagaDispatch } from '@app/client';
import { call } from 'typed-redux-saga';
import { Action } from '../action';


function* sagaStatusCheck() : Saga {
    const asset = yield* takePayload(Action.statusCheck);

    const { modelStash } =  yield* call(Api.asset.statusCheck, asset);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));
}

function* sagaExtractModelEntities() : Saga {
    const sourceModel = yield* takePayload(Action.extractModelEntities);

    yield* sagaDispatch(Action.notification({
        title : `Extracting Entities from ${sourceModel.title}`,
    }))

    const { model, modelStash } = yield* call(Api.admin.extractModelEntities, sourceModel);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));

    yield* sagaDispatch(Action.notification({
        title   : `Finished Extraction on ${model.title}`,
        message : 'Review the Results',
    }))
}

function* sagaSubmitResearchChatText() : Saga {

    const { id, research, model } = yield* takePayload(Action.submitResearchChatText);

    yield* sagaDispatch(Action.notification({
        title   : `Researching ${model.title}`,
        message : 'This will take a few moments.',
    }));

    const { modelStash } = yield* call(Api.admin.submitResearch, model, research.chatText);

    yield* sagaDispatch(Action.updateStashNoSync(modelStash));

    yield* sagaDispatch(Action.notification({
        title   : `Finished Research on ${model.title} (${id})`,
        message : 'Thanks, bye.',
    }));
}

export const AssetSaga = {
    sagaSubmitResearchChatText,
    sagaExtractModelEntities,
    sagaStatusCheck,

    *[Symbol.iterator] () {
        yield this.sagaSubmitResearchChatText;
        yield this.sagaExtractModelEntities;
        yield this.sagaStatusCheck;

    },
};
