import { RelationshipModel } from '@app/type/model/RelationshipModel';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { DocumentModel } from '@app/type/model/DocumentModel';
import { CoreHash } from '@app/type/framework/core/CoreHash';
import { createSelector } from '@reduxjs/toolkit';
import { CoreAppRoot } from '@app/type/framework/core/CoreAppRoot';


export const selectState = (
    state : CoreAppRoot,
) : CoreAppRoot => state;

export const getRelatedAssetDocumentList = (
    modelHash             : CoreHash,
    modelRelationshipList : RelationshipModel[],
    lookupDocument        : Record<string, DocumentModel>,
) : DocumentModel[] => {

    if(!modelHash)
        return [];

    return modelRelationshipList.map(relationship =>
        lookupDocument[relationship.sourceModelId as unknown as string],
    );
}

export const selectModel = createSelector(selectState, selectState => selectState.model);


export const countAttachedToDocument = (
    modelRelationshipList : RelationshipModel[],
) : number =>
    modelRelationshipList
        .reduce((count, relationship) =>
            relationship.relationshipType === RT.AttachedTo
                ? count + 1
                : count,
        0);
