const toBooleanOrExplode = (
    value : unknown, context : string = '',
) : boolean => {

    // test if the type is already a boolean
    if (typeof value === 'boolean')
        return value;

    if (typeof value === 'string') {
        if (toStringOrExplode(value) === 'true')
            return true;

        if (toStringOrExplode(value) === 'false')
            return false;
    }

    throw new Error(`Invalid boolean value: ${value} context (${context})`);
};

const toStringOrExplode = (value : unknown, context : string = '') : string => {

    if (typeof value === 'string')
        return value.trim();

    throw new Error(`Invalid string value: ${value} context (${context})`);
}


const getValue = <T = string,>(name : string) : T => {

    let value : T | null = null;

    // build injected variables (NEXT_PUBLIC_*) must be looked
    // up via dot notation because of webpack things, don't try to make it look normal,
    // it won't work. Since they're all stored the same way and treated equally as regular build time variables,
    // we push this logic down here to keep it from having to repeat logic outside of this function.

    /* eslint-disable max-len */

    switch (name) {

        //  string Values
        case 'NEXT_PUBLIC_BROWSER_WINDOW_LOCATION_ORIGIN' : return toStringOrExplode(process.env.NEXT_PUBLIC_BROWSER_WINDOW_LOCATION_ORIGIN, 'NEXT_PUBLIC_BROWSER_WINDOW_LOCATION_ORIGIN')as unknown as T;

        case 'NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY'          : return toStringOrExplode(process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,          'NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY'         )as unknown as T;
        case 'NEXT_PUBLIC_ENV_CLERK_SIGN_IN_URL'          : return toStringOrExplode(process.env.NEXT_PUBLIC_ENV_CLERK_SIGN_IN_URL,          'NEXT_PUBLIC_ENV_CLERK_SIGN_IN_URL'         )as unknown as T;
        case 'NEXT_PUBLIC_GOOGLE_ANALYTICS_ID'            : return toStringOrExplode(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,            'NEXT_PUBLIC_GOOGLE_ANALYTICS_ID'           )as unknown as T;
        case 'NEXT_PUBLIC_CLERK_SIGN_IN_URL'              : return toStringOrExplode(process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL,              'NEXT_PUBLIC_CLERK_SIGN_IN_URL'             )as unknown as T;
        case 'NEXT_PUBLIC_CLERK_SIGN_UP_URL'              : return toStringOrExplode(process.env.NEXT_PUBLIC_CLERK_SIGN_UP_URL,              'NEXT_PUBLIC_CLERK_SIGN_UP_URL'             )as unknown as T;
        case 'NEXT_PUBLIC_ENV_CLERK_DOMAIN'               : return toStringOrExplode(process.env.NEXT_PUBLIC_ENV_CLERK_DOMAIN,               'NEXT_PUBLIC_ENV_CLERK_DOMAIN'              )as unknown as T;
        case 'NEXT_PUBLIC_APP_ID'                         : return toStringOrExplode(process.env.NEXT_PUBLIC_APP_ID,                         'NEXT_PUBLIC_APP_ID'                        )as unknown as T;

        case 'NEXT_GOOGLE_GEMINI_API_KEY' : return toStringOrExplode(process.env.NEXT_GOOGLE_GEMINI_API_KEY, 'NEXT_GOOGLE_GEMINI_API_KEY') as unknown as T;

        case 'NEXT_PUBLIC_PUSHER_CLUSTER' : return toStringOrExplode(process.env.NEXT_PUBLIC_PUSHER_CLUSTER, 'NEXT_PUBLIC_PUSHER_CLUSTER') as unknown as T;
        case 'NEXT_PUBLIC_PUSHER_KEY'     : return toStringOrExplode(process.env.NEXT_PUBLIC_PUSHER_KEY,     'NEXT_PUBLIC_PUSHER_KEY'    ) as unknown as T;
        case 'NEXT_PUSHER_APP_ID'         : return toStringOrExplode(process.env.NEXT_PUSHER_APP_ID,         'NEXT_PUSHER_APP_ID'        ) as unknown as T;
        case 'NEXT_PUSHER_SECRET'         : return toStringOrExplode(process.env.NEXT_PUSHER_SECRET,         'NEXT_PUSHER_SECRET'        ) as unknown as T;

        case 'NEXT_PUBLIC_ENV_CLERK_IS_SATELLITE' : return toBooleanOrExplode(process.env.NEXT_PUBLIC_ENV_CLERK_IS_SATELLITE, 'NEXT_PUBLIC_ENV_CLERK_IS_SATELLITE') as T;

        default: value = process.env[name] as T;
    }

    /* eslint-enable max-len */

    if (!value)
        console.log('Unable to find value for', name);

    return value as T;
};

const getSubdomain = () : string | null => {
    const parts = window.location.hostname.split('.');

    if (parts.length > 2)
        return parts[0].toLowerCase().trim();
    else
        return null;
};

const getUrlSection = (index : number, pageUrl : string) : string => {
    try {
        const url = new URL(pageUrl);
        const pathParts = url.pathname.split('/');

        return pathParts[index] || '';
    } catch (e) {
        console.error(`Failed to parse URL: ${pageUrl}`, e);

        return '';
    }
};


/**
 * Parses the URL and returns the base page name
 *
 * @returns  The base page name
 */
const getBasePageName = () : string =>
    getUrlSection(1, window.location.href);

const isDevelopment = () : boolean => process.env.NODE_ENV === 'development';
const isProduction  = () : boolean => process.env.NODE_ENV === 'production';
const isClient      = () : boolean => typeof window !== 'undefined';
const isServer      = () : boolean => !isClient();
const isTest        = () : boolean => process.env.NODE_ENV === 'test';


export const Env = {
    getBasePageName,
    isDevelopment,
    isProduction,
    getSubdomain,
    getValue,
    isClient,
    isServer,
    isTest,
};
