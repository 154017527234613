export enum ContactType {
    BusinessContact = 'BusinessContact',
    ServiceProvider = 'ServiceProvider',
    OnlineFriend    = 'OnlineFriend',
    Organization    = 'Organization',
    Acquaintance    = 'Acquaintance',
    Grandparents    = 'Grandparents',
    Contractors     = 'Contractors',
    Accountants     = 'Accountants',
    Department      = 'Department',
    Colleagues      = 'Colleagues',
    Suppliers       = 'Suppliers',
    Employee        = 'Employee',
    Generic         = 'Generic',
    Suspect         = 'Suspect',
    Sibling         = 'Sibling',
    Clients         = 'Clients',
    Vendors         = 'Vendors',
    Doctors         = 'Doctors',
    Lawyers         = 'Lawyers',
    PenPal          = 'PenPal',
    Person          = 'Person',
    Friend          = 'Friend',
    Family          = 'Family',
    Parent          = 'Parent',
    InLaw           = 'InLaw',
    Group           = 'Group',
}
