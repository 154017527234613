import { localConstant } from '@app/constant/framework/MakeConstant';
import { ServerNotification } from '@app/type/framework/ServerNotification';
import { createAction } from '@reduxjs/toolkit';


const ActionTypePrefix = localConstant('Action');

export const actionName = (name : string) : string => ActionTypePrefix(name);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const action = <T = undefined>(actionNamePrefix : string) =>
    createAction<T>(actionName(actionNamePrefix));


export const SharedAction = {
    pusherNotification : action<Partial<ServerNotification>>('PusherNotification'),
};
