import { TemplateHeaderPositionType } from '@app/constant/enum/ui/TemplateHeaderPositionTypeEnum';
import { TemplateNavbarPositionType } from '@app/constant/enum/ui/TemplateNavbarPositionTypeEnum';
import { DialogModalStateType } from '@app/constant/enum/ui/DialogModalStateTypeEnum';
import { WeatherEventAnalysis } from '@app/type/control/WeatherEventAnalysis';
import { SelectModelListItem } from '@app/type/control/SelectModelListItem';
import { NotificationMessage } from '@app/type/control/NotificationMessage';
import { PlanType } from '@app/constant/enum/server/business/PlanTypeEnum';
import { ActionSelectModel } from '@app/type/dialog/ActionSelectModel';
import { ActionResearchModel } from '@app/type/ActionResearchModel';
import { ChangeSelection } from '@app/type/control/ChangeSelection';
import { RefreshModelList } from '@app/type/model/RefreshModelList';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { TranscriptModel } from '@app/type/model/TranscriptModel';
import { ActionAddReference } from '@app/type/ActionAddReference';
import { ResearchUpdate } from '@app/type/control/ResearchUpdate';
import { DialogPosition } from '@app/type/dialog/DialogPosition';
import { ObjectiveModel } from '@app/type/model/ObjectiveModel';
import { CacheNodeModel } from '@app/type/model/CacheNodeModel';
import { MilestoneModel } from '@app/type/model/MilestoneModel';
import { ActionUpdateModel } from '@app/type/ActionUpdateModel';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { CoreError } from '@app/type/framework/core/CoreError';
import { ActivityModel } from '@app/type/model/ActivityModel';
import { AnalysisModel } from '@app/type/model/AnalysisModel';
import { DocumentModel } from '@app/type/model/DocumentModel';
import { EventLogModel } from '@app/type/model/EventLogModel';
import { LocationModel } from '@app/type/model/LocationModel';
import { CoreHash } from '@app/type/framework/core/CoreHash';
import { AccountModel } from '@app/type/model/AccountModel';
import { ContactModel } from '@app/type/model/ContactModel';
import { InvoiceModel } from '@app/type/model/InvoiceModel';
import { MessageModel } from '@app/type/model/MessageModel';
import { PathwayModel } from '@app/type/model/PathwayModel';
import { PaymentModel } from '@app/type/model/PaymentModel';
import { ReportModel } from '@app/type/model/ReportModel';
import { AssetModel } from '@app/type/model/AssetModel';
import { EventModel } from '@app/type/model/EventModel';
import { ModelStash } from '@app/type/model/ModelStash';
import { O } from '@app/type/framework/data/Optional';
import { ChatModel } from '@app/type/model/ChatModel';
import { TaskModel } from '@app/type/model/TaskModel';
import { UserModel } from '@app/type/model/UserModel';
import { action } from '@app/shared/SharedAction';


export const Action = {
    adminDocumentAnalysisStatus     : action<void                        >('AdminDocumentAnalysisStatus'),
    adminDocumentIndexStatus        : action<void                        >('AdminDocumentIndexStatus'),
    startStripePortal               : action<void                        >('StartStripePortal'),
    selectPlan                      : action<PlanType                    >('SelectPlan'       ),
    updateWeatherAnalysis           : action<string                      >('UpdateWeatherAnalysis'),
    changeNavbarPosition            : action<TemplateNavbarPositionType  >('ChangeNavbarPosition' ),
    changeHeaderPosition            : action<TemplateHeaderPositionType  >('ChangeHeaderPosition' ),
    analyzeWeatherEvent             : action<WeatherEventAnalysis        >('analyzeWeatherEvent'  ),
    selectDashboardItem             : action<ChangeSelection             >('SelectDashboardItem'  ),
    selectModelListItem             : action<SelectModelListItem         >('SelectModelListItem'  ),
    selectModel                     : action<ActionSelectModel           >('SelectModel'          ),
    processingAvailableRecorderData : action<Blob                        >('ProcessingAvailableRecorderData'),
    recorderReceivedTranscript      : action<string                      >('RecorderReceivedTranscript'     ),
    recorderWebSocketOpened         : action<string                      >('RecorderWebSocketOpened'        ),
    recorderSocketClosed            : action<string                      >('RecorderSocketClosed'           ),
    recorderSocketError             : action<string                      >('RecorderSocketError'            ),
    setRecorderState                : action<boolean                     >('SetRecorderState'               ),
    webSocketEventMessage           : action<string                      >('WebSocketEventMessage'),
    webSocketEventClose             : action<string                      >('WebSocketEventClose'  ),
    webSocketEventOpen              : action<string                      >('WebSocketEventOpen'   ),
    toggleDialogFullscreen          : action<void                        >('ToggleDialogFullscreen'),
    updateDialogPosition            : action<DialogPosition              >('UpdateDialogPosition'  ),
    closeNewModelDialog             : action<ModelType                   >('CloseNewModelDialog'   ),
    openNewModelDialog              : action<ModelType                   >('OpenNewModelDialog'    ),
    monitorSingleServePageSuccess   : action<void                        >('MonitorSingleServePageSuccess'),
    updateComposeModelDetail        : action<CoreModel | null            >('UpdateComposeModelDetail'     ),
    ensureCorrectSubdomain          : action<void                        >('EnsureCorrectSubdomain'       ),
    monitorSingleServePage          : action<CoreHash                    >('MonitorSingleServePage'       ),
    setupModelDetailPage            : action<void                        >('SetupModelDetailPage'         ),
    toggleTemplateNavbar            : action<void                        >('ToggleTemplateNavbar'         ),
    confirmDeleteModel              : action<CoreModel | null            >('ConfirmDeleteModel'           ),
    selectNavbarItemId              : action<string                      >('SelectNavbarItemId'           ),
    initalizeComplete               : action<void                        >('InitalizeComplete'            ),
    refreshModelList                : action<RefreshModelList            >('RefreshModelList'             ),
    clickNavLink                    : action<void                        >('ClickNavLink'                 ),
    setAppLoaded                    : action<void                        >('SetAppLoaded'                 ),
    updateRoute                     : action<string                      >('UpdateRoute'                  ),
    pushError                       : action<CoreError                   >('PushError'                    ),
    pushRoute                       : action<string                      >('PushRoute'                    ),
    logout                          : action<void                        >('Logout'                       ),
    noOp                            : action<void                        >('NoOp'                         ),
    downloadAssetFile               : action<CoreModel                   >('DownloadAssetFile'),
    openTask                        : action<CoreHash                    >('OpenTask' ),
    contextMenuUpdateAsideStatus    : action<ActionAddReference          >('ContextMenuUpdateAsideStatus'),
    paythwayBuilderMilestonClicked  : action<string                      >('PathwayBuilderMilestonClicked'),
    contextMenuCopyToClipboard      : action<CoreModel                   >('ContextMenuCopyToClipboard'   ),
    tempDisplayResearchResult       : action<ResearchUpdate              >('TempDisplayResearchResult'    ),
    updateAdmiChatCompletion        : action<string                      >('updateAdmiChatCompletion'     ),
    contextMenuShareContent         : action<CoreModel                   >('ContextMenuShareContent'      ),
    updateResearchChatText          : action<ResearchUpdate              >('UpdateResearchChatText'       ),
    submitResearchChatText          : action<ResearchUpdate              >('SubmitResearchChatText'       ),
    adminSubmitPromptText           : action<string                      >('AdminSubmitPromptText'        ),
    toggleTemplateSidebar           : action<void                        >('ToggleTemplateSidebar'        ),
    closeTemplateSidebar            : action<void                        >('CloseTemplateSidebar'         ),
    updateStashModelSync            : action<ActionUpdateModel           >('UpdateStashModelSync'         ),
    updateAdmiChatPrompt            : action<string                      >('updateAdmiChatPrompt'         ),
    openTemplateSidebar             : action<void                        >('OpenTemplateSidebar'          ),
    updateStashNoSync               : action<ModelStash                  >('updateStashNoSync'            ),
    deleteStashModel                : action<CoreModel                   >('DeleteStashModel'             ),
    researchComplete                : action<ModelType                   >('ResearchComplete'             ),
    setAsideVisible                 : action<boolean                     >('SetAsideVisible'              ),
    notification                    : action<Partial<NotificationMessage>>('Notification'                 ),
    createModel                     : action<ActionUpdateModel           >('CreateModel'                  ),
    deleteModel                     : action<CoreModel                   >('DeleteModel'                  ),
    updateModel                     : action<CoreModel                   >('UpdateModel'                  ),
    getModel                        : action<CoreModel                   >('GetModel'                     ),
    storeAccountList                : action<O<AccountModel[]>           >('StoreAccountList'),
    storeActivityList               : action<ActivityModel[] | undefined >('StoreActivityList' ),
    storeAnalysisList               : action<O<AnalysisModel[]>          >('StoreAnalysisList'             ),
    extractModelEntities            : action<AssetModel                  >('extractModelEntities'),
    storeAssetList                  : action<O<AssetModel[]>             >('StoreAssetList'      ),
    analyzeModel                    : action<CoreModel                   >('AnalyzeModel'        ),
    statusCheck                     : action<AssetModel                  >('StatusCheck'         ),
    storeCacheNodeList              : action<O<CacheNodeModel[]>         >('StoreCacheNodeList'),
    updateChatObjective             : action<ObjectiveModel              >('UpdateChatObjective'),
    addObjectiveToChat              : action<void                        >('AddObjectiveToChat' ),
    storeChatList                   : action<O<ChatModel[]>              >('StoreChatList'),
    chatAnalysis                    : action<ChatModel                   >('ChatAnalysis' ),
    submitResearchText              : action<ActionResearchModel         >('SubmitResearchText'),
    storeContactList                : action<O<ContactModel[]>           >('StoreContactList'),
    storeModel                      : action<CoreModel | CoreModel[]     >('StoreModel'),
    storeEventLogList               : action<O<EventLogModel[]>          >('StoreEventLogList'),
    refreshEventLog                 : action<void                        >('RefreshEventLog'  ),
    storeEventList                  : action<O<EventModel[]>             >('StoreEventList'),
    storeInvoiceList                : action<O<InvoiceModel[]>           >('StoreInvoiceList'),
    storeLocationList               : action<O<LocationModel[]>          >('StoreLocationList'),
    storeMessageList                : action<O<MessageModel[]>           >('StoreMessageList'),
    storeMilestoneList              : action<O<MilestoneModel[]>         >('StoreMilestoneList'        ),
    storeObjectiveList              : action<O<ObjectiveModel[]>         >('StoreObjectiveList'),
    storeDocumentList               : action<O<DocumentModel[]>          >('StoreDocumentList'),
    startPathway                    : action<PathwayModel                >('StartPathway'            ),
    publishPathway                  : action<PathwayModel                >('PublishPathway'          ),
    selectPathwayDetailChat         : action<CoreHash                    >('SelectPathwayDetailChat' ),
    composePathwayChat              : action<DialogModalStateType        >('ComposePathwayChat'      ),
    createPathwayChat               : action<ChatModel                   >('CreatePathwayChat'       ),
    storePathwayList                : action<O<PathwayModel[]>           >('StorePathwayList'        ),
    selectPathwayAsset              : action<CoreHash                    >('SelectPathwayAsset'      ),
    storePaymentList                : action<O<PaymentModel   []>        >('StorePaymentList'   ),
    storeReportList                 : action<O<ReportModel    []>        >('StoreReportList'    ),
    storeTaskList                   : action<O<TaskModel      []>        >('StoreTaskList'      ),
    storeTranscriptList             : action<O<TranscriptModel[]>        >('StoreTranscriptList'),
    storeUserList                   : action<O<UserModel      []>        >('StoreUserList'      ),
    removeModelCacheNode            : action<CacheNodeModel              >('removeModelcacheNode'),
    removeModelActivity             : action<ActivityModel               >('removeModelactivity' ),
    removeModelAnalysis             : action<AnalysisModel               >('removeModelanalysis' ),
    removeModelDocument             : action<DocumentModel               >('removeModeldocument' ),
    removeModelEventLog             : action<EventLogModel               >('removeModeleventLog' ),
    removeModelAccount              : action<AccountModel                >('removeModelaccount'  ),
    removeModelContact              : action<ContactModel                >('removeModelcontact'  ),
    removeModelInvoice              : action<InvoiceModel                >('removeModelinvoice'  ),
    removeModelAsset                : action<AssetModel                  >('removeModelasset'    ),
    removeModelEvent                : action<EventModel                  >('removeModelevent'    ),
    removeModelModel                : action<CoreModel                   >('removeModelmodel'    ),
    removeModelChat                 : action<ChatModel                   >('removeModelchat'     ),
    locationCreate                  : action<LocationModel               >('LocationCreate'),
    removeModelLocation             : action<LocationModel               >('removeModellocation'),
    locationUpdate                  : action<LocationModel               >('LocationUpdate'),
    locationDelete                  : action<LocationModel               >('LocationDelete'),
    locationGet                     : action<LocationModel               >('LocationGet'   ),
    removeModelTranscript           : action<TranscriptModel             >('removeModeltranscript'),
    removeModelMilestone            : action<MilestoneModel              >('removeModelMilestone'),
    removeModelObjective            : action<ObjectiveModel              >('removeModelobjective'),
    removeModelMessage              : action<MessageModel                >('removeModelmessage'),
    removeModelPathway              : action<PathwayModel                >('removeModelpathway'),
    removeModelPayment              : action<PaymentModel                >('removeModelpayment'),
    removeModelReport               : action<ReportModel                 >('removeModelreport'),
    removeModelTask                 : action<TaskModel                   >('removeModeltask'),
    removeModelUser                 : action<UserModel                   >('removeModeluser'),
    pathwayGet                      : action<PathwayModel                >('PathwayGet'   ),

    contextMenuFurtherResearch : action<{
        researchQueryText : string;
        researchControlId : string;
        referenceModel    : CoreModel;
        belongsToModel    : CoreModel;
        chat             ?: ChatModel;
    }>('ContextMenuFurtherResearch'),

    updateResearchQueryText : action<{
        researchQueryText : string;
        researchControlId : string;
    }>('UpdateResearchQueryText'),
};
