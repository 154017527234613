import { PropsWithChildren, useEffect } from 'react';
import { useSession, useUser } from '@clerk/nextjs';
import { useDispatch } from './hook';
import { RFC } from '@app/ui/type';
import slice from './RootSlice';


export const ClerkUtility : RFC<PropsWithChildren> = ({ children }) => {
    const dispatch = useDispatch();

    const { user } = useUser();
    const { isLoaded, isSignedIn, session } = useSession();

    useEffect(() => {
        dispatch(slice.actions.setClerkAuthStatus({
            sessionId : session?.id,
            userId    : user?.id,
            isLoaded,
            isSignedIn,
        }));

    }, [dispatch, isLoaded, isSignedIn, session?.id, user?.id]);

    return children;
};
