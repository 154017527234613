import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { DocumentType } from '@app/constant/enum/model/DocumentTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DocumentModel } from '@app/type/model/DocumentModel';


export const DefaultDocument : DocumentModel = {

    ...DefaultCoreModel,

    documentType : DocumentType.Generic,
    modelType    : ModelType.Document,

    eTag : null,

    fileDate : null,
    fileName : null,
    filePath : null,
    fileSize : null,
    fileType : null,

    textractJobId        : null,
    textractJobStartedAt : null,
    textractStartResult  : null,

    textractDocumentAnalysis : null,
    textractResultUpdatedAt  : null,
    textractStatusMessage    : null,
    textractModelVersion     : null,
    textractNextToken        : null,
    textractJobStatus        : null,
    textractWarnings         : null,
    textractMetadata         : null,
    textractText             : null,

    analysisStatus         : null,
    analysisSummary        : null,
    analysisNotableMissing : null,
    analysisNotableUnusual : null,
    analysisLogic          : null,
    analysisFuture         : null,
    analysisGraph          : null,
    analysisHelp           : null,
} as const;


export const MakeDocument = (
    overrides : Partial<DocumentModel>,
) : DocumentModel => ({
    ...DefaultDocument,
    ...overrides,
    modelType : ModelType.Document,
});
