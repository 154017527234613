export enum ModelType {
    CheckoutSession = 'CheckoutSession',
    TextCompletion  = 'TextCompletion',
    EntityExtract   = 'EntityExtract',
    Relationship    = 'Relationship',
    Transcript      = 'Transcript',
    CacheNode       = 'CacheNode',
    Objective       = 'Objective',
    Milestone       = 'Milestone',
    Checkout        = 'Checkout',
    Analysis        = 'Analysis',
    Document        = 'Document',
    Location        = 'Location',
    Activity        = 'Activity',
    EventLog        = 'EventLog',
    Pathway         = 'Pathway',
    Message         = 'Message',
    Generic         = 'Generic',
    Contact         = 'Contact',
    Account         = 'Account',
    Invoice         = 'Invoice',
    Payment         = 'Payment',
    Unknown         = 'Unknown',
    Content         = 'Content',
    Report          = 'Report',
    Event           = 'Event',
    Asset           = 'Asset',
    Chat            = 'Chat',
    User            = 'User',
    Task            = 'Task',
    Job             = 'Job',

    PatentClaimDefinition = 'PatentClaimDefinition',
    PatentClassification  = 'PatentClassification',
    PatentDescription     = 'PatentDescription',
    PatentLimitation      = 'PatentLimitation',
    PatentParagraph       = 'PatentParagraph',
    PatentInventor        = 'PatentInventor',
    PatentSection         = 'PatentSection',
    PatentFigure          = 'PatentFigure',
    PatentNumber          = 'PatentNumber',
    PatentClaim           = 'PatentClaim',
    Patent                = 'Patent',

    PatentPublication     = 'PatentPublication',
}

