export enum RelationshipType {
    ExtractedFrom = 'ExtractedFrom',
    References    = 'References',
    UploadedTo    = 'UploadedTo',
    AttachedTo    = 'AttachedTo',
    EnrolledIn    = 'EnrolledIn',
    Duplicate     = 'Duplicate',
    CreatedBy     = 'CreatedBy',
    RelatedTo     = 'RelatedTo',
    BelongsTo     = 'BelongsTo',
    StartedBy     = 'StartedBy',
    BasedOn       = 'BasedOn',
    Generic       = 'Generic',
    OwnedBy       = 'OwnedBy',
    Unknown       = 'Unknown',
    Seeded        = 'Seeded',
    ChatTo        = 'ChatTo',
}
export { RelationshipType as RT };
