import { DialogModalStateType } from '@app/constant/enum/ui/DialogModalStateTypeEnum';
import { AssetDetailPage } from '@app/type/page/detail/AssetDetailPage';
import { DefaultAsset } from '../DefaultAsset';


export const DefaultAssetDetailPage : AssetDetailPage = {
    dialogModalStateType : DialogModalStateType.Closed,
    isResearching        : false,
    chatText             : '',
    model                : DefaultAsset,
} as const;
