import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { PathwayStatusType } from '../enum/model/PathwayStatusTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { PathwayType } from '../enum/model/PathwayTypeEnum';
import { PathwayModel } from '@app/type/model/PathwayModel';


export const DefaultPathway : PathwayModel = {

    ...DefaultCoreModel,

    pathwayType : PathwayType.Generic,
    modelType   : ModelType.Pathway,

    publishedAt : null,
    publishedBy : null,

    completedAt : null,
    startedAt   : null,

    status : PathwayStatusType.Draft,

    updateEventPromptBusiness : '',
    updateEventPromptClient   : '',

    priorityBusiness : '',
    priorityClient   : '',

    budgetBusiness : '',
    budgetClient   : '',

    processDescriptionBusiness : '',
    processDescriptionClient   : '',

    stakeholdersBusiness : '',
    stakeholdersClient   : '',

    participantBusiness : '',
    participantClient   : '',

    deadlineBusiness : null,
    deadlineClient   : null,

    titleBusiness : '',
    titleClient   : '',

    goalBusiness : '',
    goalClient   : '',

} as const;


export const MakePathway = (
    overrides : Partial<PathwayModel>,
) : PathwayModel => ({
    ...DefaultPathway,
    ...overrides,
    modelType : ModelType.Pathway,
});
