import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { MessageType } from '../enum/model/MessageTypeEnum';
import { MessageModel } from '@app/type/model/MessageModel';


export const DefaultMessage : MessageModel = {
    ...DefaultCoreModel,

    messageType : MessageType.Generic,
    modelType   : ModelType.Message,

    recipients : [],
    subject    : '',
    sentAt     : null,
    body       : '',
} as const;

export const MakeMessage = (
    overrides : Partial<MessageModel>,
) : MessageModel => ({
    ...DefaultMessage,
    ...overrides,
    modelType : ModelType.Message,
});
