import { ReviewStatusType } from '@app/constant/enum/model/ReviewStatusTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ContactType } from '@app/constant/enum/model/ContactTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { ContactModel } from '@app/type/model/ContactModel';


export const DefaultContact : ContactModel = {

    ...DefaultCoreModel,

    reviewStatus : ReviewStatusType.Unverified,

    contactType : ContactType.Generic,
    modelType   : ModelType.Contact,
} as const;

export const MakeContact = (
    overrides : Partial<ContactModel>,
) : ContactModel => ({
    ...DefaultContact,
    ...overrides,
    modelType : ModelType.Contact,
});
